<template>
    <BaseDialog
        id="pagheNota"
        icon="fal fa-cloud-question"
        min-width="50%"
        show
        title="Collegamento Pagina -> Wiki-Help"
        type="info"
        @cancel="saveAndClose"
    >
        <template #body>
            <v-card class="py-3 px-3">
                <div class="d-flex flex-column">
                    <div>
                        <v-chip
                            density="comfortable"
                            label
                            text="ID Pagina"
                            variant="tonal"
                        />
                        <v-chip
                            color="primary"
                            :text="params.helpIdPagina"
                            variant="text"
                        />
                    </div>
                    <div class="mt-2">
                        <v-chip
                            density="comfortable"
                            label
                            text="URL Help pagina"
                            variant="tonal"
                        />
                        <v-chip
                            :color="urlSuffixErr ? 'red' : 'primary'"
                            :text="urlWiki"
                            variant="text"
                        />
                    </div>
                    <BaseInputText
                        v-model="fieldsData.urlSuffix"
                        class="mt-3"
                        :error-messages="urlSuffixErr ? [urlSuffixErr] : []"
                        label="URL Help pagina"
                        :prefix="WIKI_PREFIX"
                        @change="() => (toSave = true)"
                    />
                    <BaseDatePicker
                        v-model="fieldsData.dataRif"
                        class="d-flex mt-2"
                        label="Data di riferimento"
                        :error-messages="dataRifErr ? [dataRifErr] : []"
                        @change="() => (toSave = true)"
                    />
                </div>
            </v-card>
        </template>
        <template #bodyFooterRight>
            <div class="d-flex justify-end">
                <v-btn
                    class="ml-2"
                    color="tertiary"
                    :disabled="!toSave || isError"
                    flat
                    @click="saveData"
                >
                    SALVA
                </v-btn>
                <v-btn
                    class="ml-2"
                    color="tertiary"
                    :disabled="!!globalStore.state.loading"
                    flat
                    @click="saveAndClose"
                >
                    ANNULLA
                </v-btn>
            </div>
        </template>
    </BaseDialog>
</template>

<script lang="ts">
export const WIKI_PREFIX = 'https://wiki.essepaghe.it/index.php/'
</script>

<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, onMounted, reactive, ref } from 'vue'
import { orderBy } from 'lodash'

import { useGlobalStore } from '@/stores'
import { services } from '@/api'
import dateUty from '@/scripts/services/date'
import messages from '@/scripts/utils/messages'
import BaseDatePicker from '@/components/base/BaseDatePicker.vue'
import BaseDialog from './base/BaseDialog.vue'
import BaseInputText from '@/components/base/BaseInputText.vue'

export type LinkHelpProps = {
    helpIdPagina: string
}

const props = defineProps({
    params: {
        required: true,
        type: Object as PropType<LinkHelpProps>,
    },
})

const emit = defineEmits(['reject', 'resolve'])

let origin: any = []
const globalStore = useGlobalStore()
const toSave = ref(false)
const isError = computed(() => !!(urlSuffixErr.value || dataRifErr.value))
const noCharAllowed = '%#?|<>[]{}'.split('')
const fieldsData = reactive({
    dataRif: '2020-01-01T00:00:00',
    urlSuffix: null,
})

const urlWiki = computed(
    () =>
        WIKI_PREFIX +
        (fieldsData.urlSuffix ? (fieldsData.urlSuffix as string).replaceAll(' ', '_') : ''),
)

const urlSuffixErr = computed(() => {
    if (fieldsData.urlSuffix === null) return
    if (fieldsData.urlSuffix) {
        if ((fieldsData.urlSuffix as string).split('').some(x => noCharAllowed.includes(x))) {
            return `Non sono ammessi i seguenti caratteri: ${noCharAllowed.join(', ')}`
        }
    } else {
        return "Completare l'indirizzo URL"
    }
})

const dataRifErr = computed(() => {
    if (!fieldsData.dataRif && fieldsData.dataRif !== null) {
        return 'Data di riferimento obbligatoria'
    }
})

onMounted(async () => {
    globalStore.loadingSet()
    await loadData()
    globalStore.loadingUnset()
})

async function saveAndClose() {
    if (toSave.value) {
        let result = await messages.box._saveAndClose(isError.value)
        switch (true) {
            case result.isConfirmed: // SI (if no errors), CONTINUA (if errors)
                if (!isError.value && !(await saveData())) return
                break
            case result.isDismissed: // ANNULLA
                return
        }
    }
    emit('resolve')
}

async function loadData() {
    const response = await services.APIHelp.get({ idPaginaList: [props.params.helpIdPagina] })
    const { responseStatus, payload } = response?.data
    if (responseStatus.isSuccessful) {
        origin = orderBy(payload.raccordoPaginaHelpList, 'dataInizio', ['desc'])
        if (origin.length) {
            fieldsData.dataRif = origin[0].dataInizio
            fieldsData.urlSuffix = origin[0].linkHelp
        }
    }
}

async function saveData(): Promise<boolean> {
    globalStore.loadingSet()
    const payload: HelpPayload = {
        idPagina: props.params.helpIdPagina,
        linkHelp: fieldsData.urlSuffix!,
        periodoDiRiferimento: dateUty.toObject(fieldsData.dataRif) as any,
    }
    const item = origin.find((i: any) =>
        dateUty.isBetween(fieldsData.dataRif, i.dataInizio, i.dataFine),
    )
    payload.id = item?.id || null
    console.log(payload.id, item)

    const call = payload.id ? 'save' : 'add'
    const response = await services.APIHelp[call](payload)
    globalStore.loadingUnset()
    if (response?.data.responseStatus?.isSuccessful) {
        messages.toast.success('Collegamento pagina Help salvato con successo.')
        emit('resolve')
    }
    return response?.data.responseStatus?.isSuccessful
}
</script>

<style lang="scss" />
