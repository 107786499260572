import messages from '@/scripts/utils/messages'
import dateUty, { type DateType } from '@/scripts/services/date'

type Note = {
    id: number | null
    entita: string | null
    codice: string | null
    titolo: string | null
    testo: string | null
}

export type VociDes = {
    codDitta: string
    codUP: string
    codVoce: string
    dataInizio: string
    dataFine: string
    descrizione: string
    nuovaDescrizione: string
}

export async function trascodificaDesVoci(
    vociDes: VociDes[],
    vociItems: CodDes[] | Map<string, CodDes>,
    periodo: DateType,
    codDitta: string | null = null,
    codUP: string | null = null,
) {
    const _vociItems = vociItems instanceof Map ? Array.from(vociItems.values()) : vociItems
    // Scremature degli elementi non interessati dal ciclo FOR successivo
    const codVoci = _vociItems.map(v => v.codice)
    const _vociDes = vociDes.filter(
        vd =>
            codVoci.includes(vd.codVoce) &&
            dateUty.isBetween(
                periodo,
                dateUty.toObject(vd.dataInizio),
                dateUty.toObject(vd.dataFine),
            ),
    )
    // Decodifica descrizione
    let descrizione
    _vociItems.forEach(v => {
        descrizione = undefined
        descrizione = _vociDes.find(
            vd => vd.codVoce === v.codice && vd.codDitta === codDitta && vd.codUP === codUP,
        )?.nuovaDescrizione
        if (!descrizione) {
            descrizione = _vociDes.find(
                vd => vd.codVoce === v.codice && vd.codDitta === codDitta && !vd.codUP,
            )?.nuovaDescrizione
        }
        if (!descrizione) {
            descrizione = _vociDes.find(
                vd => vd.codVoce === v.codice && !vd.codDitta && !vd.codUP,
            )?.nuovaDescrizione
        }
        if (descrizione) v.descrizione = descrizione
    })
}

export function createLegenda(item: {
    tipoVoce: string
    voceRisultatoList: Array<{ codRii: string }>
}) {
    const codRiiMap: { [key: string]: string } = {
        '00210': 'A',
        '00215': 'B',
        '00222': 'D',
        '00224': 'F',
        '00240': 'H',
        '00263': 'L',
        '00300': 'P',
        '00310': 'Q',
        '00315': 'R',
        '00255': 'S',
        '00256': 'S',
        '00355': 'Z',
    }

    const data = [
        item.tipoVoce,
        ...item.voceRisultatoList
            .map(element => codRiiMap[element.codRii] || '')
            .filter(el => el !== ''),
    ].join(',')

    return data
}

export function selectNota(item: ElabDipteMovVoce, dataNote: Note[]) {
    const note = dataNote?.filter(el => el.codice == item.codice)
    let testo = `<ul>`
    note?.forEach(el => {
        testo += `<li>${el.testo}</li>`
    })
    testo.concat('</ul>')
    messages.box.info(testo)
}

export default {
    createLegenda,
    selectNota,
    trascodificaDesVoci,
}
