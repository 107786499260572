import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'
import { BASE_URL_DITTA } from './ditta'

const BASE_URL_ENTE_FISSO = '/DittaUPOpzione'

class API {
    @call({ url: `${BASE_URL_ENTE_FISSO}/GetDitteUPOpzione` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaUPOpzione>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DITTA}/DeleteDittaUPOpzione`, method: 'delete' })
    static async delete(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BasicResponsePayload<{ id: number | null }>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.delete,
}
