import { AxiosResponse } from 'axios'

import { call } from '@/scripts/core/axios'

const BASE_URL = '/FormulaEsempio'

class API {
    @call({ url: `${BASE_URL}/GetFormulaEsempio` })
    static async getFormulaEsempio<P, R>(
        payload?: P,
    ): Promise<AxiosResponse<BasicResponseData<R>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveFormulaEsempio` })
    static async saveFormulaEsempio<P>(
        payload?: P,
    ): Promise<AxiosResponse<BasicResponsePayload<P>> | null> {
        return null
    }
}

export default {
    getFormulaEsempio: API.getFormulaEsempio<Payload, FormulaEsempio>,
    saveFormulaEsempio: API.saveFormulaEsempio<SaveFormulaEsempioPayload>,
}
