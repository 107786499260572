import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/DipteDoppioCedolino'

class API {
    @call({ url: `${BASE_URL}/CreateDipteDoppioCedolino` })
    static async create(
        payload: CreateDipteDoppioCedolinoPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteDoppioCedolino>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveDipteDoppioCedolino` })
    static async save(
        payload: SaveDipteDoppioCedolinoPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteDoppioCedolino>> | null> {
        return null
    }
}

export default {
    create: API.create,
    save: API.save,
}
