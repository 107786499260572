import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL_ALTERADITTA = '/DipteAlteraDitta'

class API {
    @call({ url: `${BASE_URL_ALTERADITTA}/GetDipendentiAlteraDitta` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DipteAlteraDitta>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_ALTERADITTA}/UpdateDipteAlteraDitta`, method: 'put' })
    static async save(
        payload: saveDipteAlteraDittaPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteAlteraDitta>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_ALTERADITTA}/CreateDipteAlteraDitta` })
    static async create(
        payload: saveDipteAlteraDittaPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteAlteraDitta>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
    create: API.create,
}
