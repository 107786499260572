import { defineStore } from 'pinia'
import { ref, reactive, type Ref, shallowRef, computed, ShallowRef, shallowReactive } from 'vue'
import { useRoute } from 'vue-router'
import { pagheRoutes } from '@/routes'
import { useGlobalStore } from '@/stores'
import { ParametriState } from '../../views/utility/nettoLordoCosto/childs/Parametri.vue'
const dataLookup = shallowReactive(new Map())
const storeName = 'nettoLordoCosto'
const useNettoLordoCostoStore = defineStore(storeName, () => {
    const actualIndex = ref(0)
    const globalStore = useGlobalStore()
    const currentPage = ref(1)
    const typeParams = ref()

    const expandedSections = ref([]) as SectionKeys[]
    const expandAll = ref(false)

    const dataParametri: Ref<ParametriState | null> = ref<ParametriState | null>(null)
    const savedTableData = ref<{ idDipte: number; idDitta: number; rows: any[] }[]>([])
    const elencoDitte = ref({
        filtro: ref(''),
        selectedKeys: ref<boolean | number[]>(false),
        sortBy: ref([
            { key: 'codiceDitta', order: 'asc' },
            { key: 'unitaProduttiva', order: 'asc' },
        ]),
        filtroLavoratori: ref(''),
        filtroDitteGestite: ref(true),
        filtroDitteTipo: ref([1, 2]),
        filtroDitteUP: ref(false),
    })

    const dateDitta = ref()
    const dataDittaList: ShallowRef<DittaResponse[] | undefined> = shallowRef()

    function resetDitte() {
        elencoDitte.value.filtro = ''
        elencoDitte.value.selectedKeys = false
        elencoDitte.value.filtroLavoratori = ''
        elencoDitte.value.filtroDitteGestite = true
        elencoDitte.value.filtroDitteUP = false
        dateDitta.value = null
        dataDittaList.value = []
    }

    const typeDipte = ref('')
    const elencoDipendenti = ref({
        filtro: ref(''),
        selectedKeys: ref<boolean | number[]>(true),
        sortBy: ref([
            { key: 'unitaProduttiva', order: 'asc' },
            { key: 'codice', order: 'asc' },
            { key: 'nominativo', order: 'asc' },
        ]),
        unitaProduttiva: ref(''),
        escludiCessati: ref(1),
    })
    const dateDipendenti = ref()
    const dateDipendentiList: ShallowRef<DipteResponse[] | undefined> = shallowRef()
    const dateDipendentifilterList = ref([])

    function resetDipte() {
        elencoDipendenti.value.filtro = ''
        elencoDipendenti.value.selectedKeys = false
        elencoDipendenti.value.filtroLavoratori = ''
        elencoDipendenti.value.filtroDitteGestite = true
        elencoDipendenti.value.filtroDitteUP = false
        dateDipendenti.value = null
        dateDipendentiList.value = []
        dateDipendentifilterList.value = []
    }

    const periodo = ref(globalStore.state.periodoElab)

    const selectedDipteRow = ref()

    function getDataDipte() {
        if (
            dateDipendentiList.value &&
            elencoDipendenti.value.selectedKeys.length > 0 &&
            typeParams.value != 'T'
        ) {
            selectedDipteRow.value = 0
            return dateDipendentiList.value
                .filter((dipendente: DipteResponse) =>
                    Object.values(elencoDipendenti.value.selectedKeys).includes(dipendente.id),
                )
                .map((dipendente: DipteResponse) => ({
                    ...dipendente,
                    nome: `${dipendente.nome} ${dipendente.cognome}`,
                }))
        } else if (dateDipendentiList.value && typeParams.value == 'T') {
            selectedDipteRow.value = 0
            return [dateDipendenti.value]
        }
        return []
    }

    function getDipendenteById(id: number) {
        if (dateDipendentiList.value) {
            const index = dateDipendentiList.value.findIndex(dipendente => dipendente.id === id)
            if (index !== -1) {
                return { data: dateDipendentiList.value[index], index }
            }
        }
        return { data: null, index: -1 }
    }

    function updateSingleRowData(
        codice: string | number,
        rowData: any,
        idDipte: number,
        idDitta: number,
    ) {
        const existingEntryIndex = savedTableData.value.findIndex(
            entry => entry.idDipte === idDipte && entry.idDitta === idDitta,
        )

        if (existingEntryIndex !== -1) {
            // Update existing entry
            const existingEntry = savedTableData.value[existingEntryIndex]
            // Salva direttamente i dati per il codice
            existingEntry.data = {
                ...existingEntry.data,
                [codice]: rowData,
            }
        } else {
            // Create new entry
            savedTableData.value.push({
                idDipte,
                idDitta,
                data: { [codice]: rowData },
            })
        }
    }

    function getSavedData(idDipte: number, idDitta: number) {
        return (
            savedTableData.value.find(
                entry => entry.idDipte === idDipte && entry.idDitta === idDitta,
            )?.data || {}
        )
    }

    const keysLookup = {
        COMUNE_RESIDENZA: 'comuneTipo2',
        COD_ALIQUOTA: 'codAliquota',
        COD_ALIQUOTA_Z1_Z2: 'codAliquota_Z1_Z2', // DIPENDE COD_ALIQUOTA
        COD_COTRATTO: 'codContratto',
        COD_COTRATTO_SUDDIVISIONE: 'codContrattoSuddivisione',
        COD_COTRATTO_SUDDIVISIONE_C: 'codContrattoSuddivisione_C', // DIPENDE COD_COTRATTO_SUDDIVISIONE
        COD_COTRATTO_SUDDIVISIONE_T: 'codContrattoSuddivisione_T', // DIPENDE COD_COTRATTO_SUDDIVISIONE
        COD_SGRAVIOFISCALE_S: 'codSgravio',
        CONTRATTO_APPRENDISTATO: 'contrattoApprendistato', // DIPENDE DAL CONTRATTO
        CONTRATTO_FATTISPECIE: 'codFattispecie', // DIPENDE DAL CONTRATTO
        CONTRATTO_LIVELLO: 'contrattoLivello', // DIPENDE DAL CONTRATTO
        CASSA_EDILE: 'codCassaEdile',
        DITTA_GR_ALTERAZIONI: 'dittaGruppoAlterazione', // DIPENDE DALLA DITTA
        DITTA_GR_ALTERAZIONI_A: 'dittaGruppoAlterazione_A',
        DITTA_GR_ALTERAZIONI_B: 'dittaGruppoAlterazione_B',
        DITTA_GR_ALTERAZIONI_C: 'dittaGruppoAlterazione_C',
        DITTA_GR_ALTERAZIONI_D: 'dittaGruppoAlterazione_D',
        DITTA_GR_ALTERAZIONI_E: 'dittaGruppoAlterazione_E',
        DITTA_GR_ALTERAZIONI_F: 'dittaGruppoAlterazione_F',
        RETRIBUZIONE_CONVENZIONALE_CODICE: 'codRetrConvenzionale',
        RETRIBUZIONE_CONVENZIONALE_CODICE_CODICE: 'codRetrConvenzionale_codice', // DIPENDE DA RETRIBUZIONE_CONVENZIONALE_CODICE
        VCODES_FDO_RESIDUALE: 'fdoResiduale',
        VCODES_ADDIZIONALI_NLC: 'addizionaliNlc',
        VCODES_COD_SOMMAINTEGRAT: 'codSommaintegrat',
        VCODES_COLLABORAZIONI: 'collaborazioni',
        VCODES_CRED_80_EURO: 'cred80Euro',
        VCODES_ESP_PAGA_CEDOLINO: 'espPagaCedolino',
        VCODES_OPZIONI_DETR_DIP: 'opzioniDetrDip',
        VCODES_RETRIBUZ_MOVIMENTI: 'retribuzMovimenti',
        VCODES_SINO: 'siNo',
        VCODES_TIPO_APPR: 'tipoAppr',
        VCODES_TIPO_CONTR_DIT_APP: 'tipoContrDitApp',
        VCODES_TIPO_PART_TIME: 'tipoPartTime',
        VINCENTIVO: 'vIncentivo',
        VCOTRATTO_INPS: 'vContrattoInps',
        VTIPO_RAPPORTO: 'vTipoRapporto',
        VQUALIFICA_DIPTE: 'vQualificaDipte',
    }

    function clearSelectedLookups() {
        dataLookup.clear()
    }

    return {
        actualIndex,
        dataDittaList,
        elencoDitte,
        dateDitta,
        typeDipte,
        elencoDipendenti,
        dateDipendenti,
        dateDipendentiList,
        dateDipendentifilterList,

        dataParametri,

        selectedDipteRow,
        typeParams,

        currentPage,
        periodo,

        getDataDipte,
        updateSingleRowData,
        getSavedData,
        resetDitte,
        resetDipte,
        clearSelectedLookups,

        dataLookup,
        expandedSections,
        expandAll,
        keysLookup,
    }
})

export default { useNettoLordoCostoStore, storeName }
