<template>
    <div class="base-input-lookup">
        <component
            :is="isSelect ? VSelect : VAutocomplete"
            ref="baseInputLookup"
            v-bind="attrs"
            auto-select-first
            :class="{ 'v-input--warning': isWarning }"
            clear-icon="fas fa-xmark"
            :clearable="isClearable && !isSelect"
            :custom-filter="filter"
            :chips="multiple"
            density="compact"
            :filter-keys="['descrizione']"
            :hide-details="hintMessage === false || attrs.disabled || globalStore.isLoading"
            hide-no-data
            :items="items"
            :item-title="nullDescription && modelValue == null ? 'descrizione' : itemTitle"
            :item-value="itemValue"
            :label="label"
            :model-value="codeFormat(modelValue)"
            :menu-props="{
                contentClass: 'baseinputlookup-menu',
                maxWidth: `${maxWidth}%`,
            }"
            :multiple="multiple"
            :messages="messages"
            :error-messages="errorMessages"
            persistent-placeholder
            :tabindex="attrs.readonly ? '-1' : null"
            variant="outlined"
            @keydown="onKeyDown"
            @update:model-value="onChange"
            @update:focused="onFocused"
        >
            <template #append-inner>
                <div class="d-flex">
                    <v-icon
                        v-if="searchStartsWith"
                        id="startsWith"
                        class="starts-with mr-1"
                        :icon="`fas fa-toggle-o${startsWith ? 'n' : 'ff'}`"
                        size="18"
                        @click="startsWith = !startsWith"
                    />
                    <v-tooltip
                        activator="parent"
                        location="top"
                        text="Ricerca elementi che iniziano con ..."
                    />
                </div>
                <slot name="append-inner" />
            </template>
            <template
                v-if="multiple"
                #chip="scoped"
            >
                <v-chip
                    :class="{ 'ml-1': scoped.index }"
                    :color="scoped.item?.raw?.chip?.color"
                    :prepend-icon="scoped.item?.raw?.chip?.icon"
                    text=""
                    variant="outlined"
                />
            </template>
            <template
                v-if="multiple"
                #item="scoped"
            >
                <div class="d-flex align-center m-0">
                    <v-checkbox
                        class="mx-1"
                        density="comfortable"
                        hide-details
                        :model-value="!!attrs.modelValue.includes(scoped.item.value)"
                        @update:model-value="(val: unknown) => chipAdd(val as boolean, scoped.item)"
                    />
                    <v-chip
                        class="pt-1"
                        :color="scoped?.item?.raw?.chip?.color"
                        density="comfortable"
                        :prepend-icon="scoped?.item?.raw?.chip?.icon"
                        :text="` ${scoped?.item?.raw?.descrizione}`"
                        variant="text"
                    />
                </div>
            </template>
            <template
                v-if="!multiple"
                #item="scoped"
            >
                <v-list-item
                    v-bind="scoped.props"
                    min-height="48"
                >
                    <template #title>
                        <div class="d-flex align-center">
                            <div
                                v-if="showItemValue"
                                class="item__id"
                            >
                                <span>{{ scoped.item?.raw?.[itemValue] }}</span>
                            </div>
                            <div :class="`item__title ml-${showItemValue ? '5' : '0'}`">
                                <span :class="{ bold: !showItemValue }">
                                    {{ getColumnValue(scoped.item?.raw, itemDescription) }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template
                        v-if="subTitleColumns(scoped.item?.raw).length"
                        #subtitle
                    >
                        <div class="d-flex flex-wrap align-center pl-3">
                            <template v-for="(col, idx) in subTitleColumns(scoped.item?.raw)">
                                <div
                                    v-if="col.value"
                                    :key="`column${idx}`"
                                    class="subtitle__item d-flex py-1 pr-4"
                                >
                                    <span :class="col.column.type === 'check' ? 'value' : 'label'">
                                        {{ col.column.title }}
                                    </span>
                                    <v-spacer />
                                    <span
                                        v-if="col.column.type !== 'check'"
                                        class="value pl-1"
                                    >
                                        {{ col.value }}
                                    </span>
                                </div>
                            </template>
                        </div>
                    </template>
                </v-list-item>
            </template>
        </component>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, useAttrs } from 'vue'
import { VAutocomplete } from 'vuetify/components/VAutocomplete'
import { VSelect } from 'vuetify/components/VSelect'
import { castArray, cloneDeep, get, remove, set } from 'lodash'
import Inputmask from 'inputmask'

import { useGlobalStore } from '@/stores'
import utyMessages from '@/scripts/utils/messages'

type ListType = string | { name?: string; nullDescription?: string }

interface Column {
    key: string
    title: string
    type?: 'check' | 'text'
}

export interface IBaseInputLookup {
    beforeSelect?: null | ((values: { currentValue: any; oldValue: any }) => Promise<boolean>)
    codeType?: string
    columns?: { columns: Column[] }
    hintMessage?: false | string | { key: string; showTitle: boolean }
    isSelect?: boolean
    itemDescription?: string
    items?: any[]
    itemTitle?: string
    itemValue?: string
    label: string
    list?: ListType
    maxWidth?: number
    multiple?: boolean
    nullDescription?: string | boolean //se boolean usa la descrizione da BE
    searchMinLength?: number
    searchStartsWith?: boolean
    showItemValue?: boolean
    warningMessages?: string | string[]
}

const props = withDefaults(defineProps<IBaseInputLookup>(), {
    beforeSelect: null,
    codeType: 'text',
    columns: () => ({
        columns: [
            { key: 'codice', title: 'Codice', type: 'text' },
            { key: 'descrizione', title: 'Descrizione', type: 'text' },
        ],
    }),
    hintMessage: 'descrizione',
    isSelect: false,
    itemDescription: 'descrizione',
    itemTitle: 'codice',
    itemValue: 'codice',
    items: () => [],
    maxWidth: 0.4,
    showItemValue: true,
    searchStartsWith: false,
    searchMinLength: 3,
    warningMessages: () => [],
    multi: false,
})

const attrs: any = useAttrs()
const modelValue = computed(() => get(attrs, 'model-value', attrs.modelValue))
const emits = defineEmits(['change', 'update:modelValue'])
const globalStore = useGlobalStore()
const baseInputLookup = ref()
const startsWith = ref(!!props.searchStartsWith)
const searchMinLength = ref(props.searchMinLength)
const maxWidth = ref(
    ((document.querySelector('.v-main') as HTMLElement).offsetWidth || 0) * props.maxWidth,
)

const errorMessages = computed(() => {
    if (props.multiple || attrs.disabled) return []
    if (!attrs?.errorMessages && modelValue.value && !getItemByValue(modelValue.value)) {
        return ['Codice non trovato']
    }
    return attrs?.errorMessages
})
const isClearable = computed(() =>
    typeof attrs.clearable === 'boolean' ? attrs.clearable : !attrs?.readonly,
)

const isWarning = computed(() => !attrs.errorMessages?.length && !!props.warningMessages.length)

const items = computed(() => {
    let items = cloneDeep(props.items)
    switch (props.codeType) {
        case 'codeEnte':
            items.forEach(
                (item: any) => (item[props.itemValue] = codeFormat(item[props.itemValue])),
            )
            break
    }
    //Aggiunta elemento fittizio corrispondente al valore NULL
    if (typeof props.nullDescription === 'string')
        items.unshift({ codice: null, descrizione: props.nullDescription })
    return items
})

const messages = computed(() => {
    // warnings
    let messages: string[] = castArray(props.warningMessages).slice(0, 1)
    // messages
    if (!messages.length && attrs.messages) {
        messages = castArray(attrs.messages).slice(0, 1) as typeof messages
    }
    // hint
    if (props.nullDescription && modelValue.value == null) return messages

    if (!messages.length && props.hintMessage && props.hintMessage !== props.itemTitle) {
        let hintKey: string
        let showHintTitle: boolean
        if (typeof props.hintMessage === 'string') {
            hintKey = props.hintMessage
            showHintTitle = false
        } else {
            hintKey = props.hintMessage.key
            showHintTitle = !!props.hintMessage.showTitle
        }
        if (hintKey) {
            const item = getItemByValue(modelValue.value)
            const value = item ? get(item, hintKey, '') : null
            if (value) {
                let message = ''
                if (showHintTitle) {
                    const column = props.columns.columns.find(
                        (column: Column) => column?.key === hintKey,
                    )
                    if (column) message += `${column?.title} `
                }
                message += value
                messages.push(message)
            }
        }
    }
    //

    return messages
})

function chipAdd(selected: boolean, item: any) {
    let attrVal = get(attrs, 'model-value', attrs.modelValue)
    if (selected) {
        attrVal.push(item.props.value)
        attrVal = attrVal.sort()
    } else {
        remove(attrVal, el => el == item.props.value)
    }
    emits('update:modelValue', attrVal)
}

function codeFormat(code: string) {
    switch (props.codeType) {
        case 'codeEnte':
            return code?.length > 4 ? `${code.slice(0, 4)}.${code.slice(4)}` : code
        default:
            return code
    }
}

function filter(
    value: string,
    query: string,
    item?: any,
): boolean | number | [number, number] | [number, number][] {
    const minLength = searchMinLength.value ? searchMinLength.value : 3
    return props.columns?.columns
        .filter((column: Column) => {
            return query?.length <= minLength ? column.key === props.itemValue : true
        })
        .some((column: Column) => {
            let value = getColumnValue(item.raw, column)
            if (value) {
                value = value.toString().trim().toLowerCase()
                if (startsWith.value) {
                    return value.startsWith(query.toLowerCase())
                } else {
                    return value.includes(query.toLowerCase())
                }
            }
            return false
        })
}

function getColumnValue(item: any, column: string | Column) {
    const _column =
        typeof column === 'string'
            ? props.columns.columns.find((col: Column) => col.key === column)
            : column
    return _column ? get(item, _column.key, _column.type === 'check' ? false : '') : ''
}

function getItemByValue(value: any) {
    return items.value?.find((item: any) => item[props.itemValue] == value) || null
}

const focused = ref(false)
function onFocused(newValue: boolean) {
    focused.value = newValue
}

async function onChange(newValue: any) {
    switch (props.codeType) {
        case 'codeEnte':
            if (newValue) newValue = newValue.replace('.', '')
            break
    }
    let awaitResponse = false
    const values = {
        currentValue: getItemByValue(newValue),
        oldValue: getItemByValue(modelValue.value),
    }
    if (props.beforeSelect) {
        const result = await props.beforeSelect(values)
        if (!result) return
        awaitResponse = true
    }
    emits('update:modelValue', newValue)

    if (
        focused.value ||
        awaitResponse ||
        !!baseInputLookup.value?.$el.querySelector('.v-input__control .v-field--focused')
    ) {
        emits('change', values)
    }
}

function onKeyDown(event: any) {
    const lowerKey = event.key.toLowerCase()
    if (attrs.tabInfo && event.ctrlKey && event.altKey && lowerKey === 'i') {
        const msg = `La tabella interessata è ${
            typeof attrs.tabInfo === 'function' ? attrs.tabInfo() : attrs.tabInfo
        }`
        utyMessages.box.info(msg)
    }
}

function subTitleColumns(item: any) {
    return props.columns.columns
        .filter((col: Column) => {
            return ![props.itemTitle, props.itemValue, props.itemDescription].includes(col.key)
        })
        .map((column: Column) => ({ column, value: getColumnValue(item, column) }))
}

function maskInput() {
    if (!props.codeType) return
    const input = baseInputLookup.value.$el.querySelector('.v-field input')
    if (input) {
        const settings: any = {
            nullable: true,
            placeholder: '',
            prefillYear: false,
        }
        let mask = false
        switch (props.codeType) {
            // SPECIFICI
            case 'codeEnte':
                mask = true
                settings.mask = '9{4}[.9{4}]'
                break
        }
        if (mask) Inputmask(settings).mask(input)
    }
}

onMounted(() => {
    maskInput()
    nextTick(() => {
        // maxWidth.value = document
    })
})
</script>

<style lang="scss">
@use '@/styles/global/mixins';
@use '@/styles/global/vars';
.base-input-lookup {
    @include mixins.input_field;
    .v-autocomplete__selection .v-autocomplete__selection-text,
    .v-select__selection .v-select__selection-text {
        font: normal normal normal 14px/24px Roboto;
    }
}

// ce ne può essere solo una di questa classe in quanto solo una lookup alla volta può avere il menù aperto
.baseinputlookup-menu {
    .v-list-item {
        &:not(:last-child) {
            border-bottom: 1px solid var(--line-separator-color);
        }
        &.v-list-item--active {
            background-color: var(--active-item-bg);
        }
        .v-list-item-subtitle {
            opacity: unset;
        }
        .item {
            &__id {
                span {
                    font-family: 'Roboto-Condensed', Arial, Helvetica, sans-serif;
                    font-weight: 600;
                }
            }
            &__title {
                white-space: wrap;
                span {
                    font-weight: 400;
                    font-family: 'Roboto-Light', Arial, Helvetica, sans-serif;
                    font-size: 0.85rem;
                    &.bold {
                        font-weight: 700;
                    }
                }
            }
        }
        .subtitle {
            &__item {
                align-items: flex-end;
                padding-top: 0px !important;

                .label,
                .value {
                    font-size: 0.85rem;
                    font-weight: 500;
                }
                .label {
                    opacity: 0.9;
                    font-weight: unset;
                    &::after {
                        content: ':';
                    }
                }
            }
        }
    }
}
</style>
