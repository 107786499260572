import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL_UP_STORICO = '/DittaUPStorico'

class API {
    @call({ url: `${BASE_URL_UP_STORICO}/GetDitteUPStorico` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaUPStorico>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_UP_STORICO}/CreateDittaUPStorico` })
    static async save(
        payload: CreateDittaRappresentante,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaUPStorico>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_UP_STORICO}/UpdateDittaUPStorico`, method: 'put' })
    static async update(
        payload: UpdateDittaRappresentante,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaUPStorico>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_UP_STORICO}/DeleteDittaUPStorico`, method: 'delete' })
    static async delete(
        payload: BaseDeletePayload,
    ): Promise<AxiosResponse<BasicResponsePayload<{ id: number }>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
    update: API.update,
    delete: API.delete,
}
