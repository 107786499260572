<template>
    <div v-if="readOnly">
        <v-icon
            aria-label="Pagina in sola lettura"
            class="text-warning"
            icon="fas fa-lock-keyhole"
            tabindex="0"
        />
        <v-tooltip
            activator="parent"
            location="top"
            role="tooltip"
            text="Solo consultazione"
        />
    </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { castArray, values } from 'lodash'

import useStorePermissions from '@paghe/stores/core/pagePermission'

export interface ReadOnlyProps {
    isReadonly?: undefined | boolean
}

const props = withDefaults(defineProps<ReadOnlyProps>(), {
    isReadonly: undefined,
})

const permissionsStore = useStorePermissions()

const readOnly = computed(() => {
    if (props.isReadonly !== undefined) return props.isReadonly
    const permRoute = useRoute()?.meta.permissions as { [key: string]: any }
    if (!permRoute) return false
    const codTipoPermesso = permissionsStore.getPermissionType(permRoute.codPermesso)
    return values(castArray(codTipoPermesso)).every(p => p !== 'A')
})

defineExpose({ readOnly })
</script>

<style lang="scss">
@use '@/styles/global/vars';
</style>
