import { call } from '@/scripts/core/axios'
import type { AxiosResponse } from 'axios'

const BASE_URL = '/Contratto'

class API {
    @call({ url: `${BASE_URL}/GetContratti` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<Contratto>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetElencoQualificheFattispieceGruppiAlterazione` })
    static async getElencoQualificheFattispieceGruppiAlterazione(
        payload: GetElencoQualificheFattispieceGruppiAlterazionePayload,
    ): Promise<AxiosResponse<
        BasicResponsePayload<{
            elencoQualificheFattispieceGruppiAlterazione: QualificheFattispieceGruppiAlterazione[]
        }>
    > | null> {
        return null
    }

    @call({ url: `${BASE_URL}/DuplicaContratto` })
    static async duplicaContratto(
        payload: DuplicaContrattoPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<Contratto>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetContrattoElencoCodiciElementiPaga` })
    static async getContrattoElencoCodiciElementiPaga(
        payload: GetContrattoElencoCodiciElementiPaga,
    ): Promise<AxiosResponse<BasicResponsePayload<CodiceElementoPagaElementoContratto[]>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoGenerale` })
    static async saveContrattoGenerale(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoGenerale>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoSuddivisione` })
    static async saveContrattoSuddivisione(
        payload: SaveContrattoSuddivisione,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoSuddivisione>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoDivisore` })
    static async saveContrattoDivisore(
        payload: SaveContrattoDivisore,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoDivisore>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoIstitutoContrattuale` })
    static async saveContrattoIstitutoContrattuale(
        payload: SaveContrattoIstitutoContrattuale,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoIstitutoContrattuale>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoStraordinario` })
    static async saveContrattoStraordinario(
        payload: SaveContrattoStraordinario,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoStraordinario>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoFestivita` })
    static async saveContrattoFestivita(
        payload: SaveContrattoFestivita,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoFestivita>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/SaveContrattoFerieExFestRol` })
    static async saveContrattoFerieExFestRol(
        payload: SaveContrattoFerieExFestRol,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoFerieExFestRol>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoMensilitaAggiuntiva` })
    static async saveContrattoMensilitaAggiuntiva(
        payload: SaveContrattoMensilitaAggiuntiva,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoMensilitaAggiuntiva>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoInfortunio` })
    static async saveContrattoInfortunio(
        payload: SaveContrattoInfortunio,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoInfortunio>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoMalattia` })
    static async saveContrattoMalattia(
        payload: SaveContrattoMalattia,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoMalattia>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/SaveContrattoMaternita` })
    static async saveContrattoMaternita(
        payload: SaveContrattoMaternita,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoMaternita>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoUnaTantum` })
    static async saveContrattoUnaTantum(
        payload: SaveContrattoUnaTantum,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoUnaTantum>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/SaveContrattoElePaga` })
    static async saveContrattoElePaga(
        payload: SaveContrattoElePaga,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoElePaga>> | null> {
        return null
    }
}

export default {
    get: API.get,
    getElencoQualificheFattispecieGruppiAlterazione:
        API.getElencoQualificheFattispieceGruppiAlterazione,
    getContrattoElencoCodiciElementiPaga: API.getContrattoElencoCodiciElementiPaga,
    saveContrattoIstitutoContrattuale: API.saveContrattoIstitutoContrattuale,
    saveContrattoDivisore: API.saveContrattoDivisore,
    saveContrattoFestivita: API.saveContrattoFestivita,
    saveContrattoGenerale: API.saveContrattoGenerale,
    saveContrattoStraordinario: API.saveContrattoStraordinario,
    saveContrattoSuddivisione: API.saveContrattoSuddivisione,
    saveContrattoFerieExFestRol: API.saveContrattoFerieExFestRol,
    saveContrattoMensilitaAggiuntiva: API.saveContrattoMensilitaAggiuntiva,
    saveContrattoInfortunio: API.saveContrattoInfortunio,
    saveContrattoMalattia: API.saveContrattoMalattia,
    saveContrattoMaternita: API.saveContrattoMaternita,
    saveContrattoUnaTantum: API.saveContrattoUnaTantum,
    saveContrattoElePaga: API.saveContrattoElePaga,
    duplicaContratto: API.duplicaContratto,
}
