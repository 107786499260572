import { castArray } from 'lodash'

interface EventOnChange extends Event {
    target: (EventTarget & { files?: File[] }) | null
}

export class LoadFile {
    private input: HTMLInputElement

    constructor(private attrs?: { [key: string]: any }) {
        this.input = document.createElement('input')
        this.input.type = 'file'
    }

    public async load(
        extensions: string | string[],
        toBase64 = false,
    ): Promise<File | string | undefined> {
        return new Promise((resolve, reject) => {
            let accept = ''
            castArray(extensions).forEach((ext: string) => {
                if (accept) accept += ','
                accept += `.${ext}`
            })
            this.input.accept = accept
            this.input.onchange = async (event: EventOnChange): Promise<any> => {
                const file: File | undefined = event.target?.files?.[0]
                if (file) resolve(toBase64 ? this.toBase64(file) : file)
            }
            this.input.oncancel = () => {
                resolve(undefined)
            }
            this.input.click()
        })
    }

    public async toBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result as string)
            reader.onerror = reject
        })
    }
}

export default { LoadFile }
