import core from './core'
import services from './services'

function progressivoCodiceCustom(codici: string[], codiceLen: Exclude<number, 0 | 1 | 2> = 5) {
    if (codiceLen < 3) return
    const _codici = codici.slice().sort()
    const codice = _codici.at(-1)
    const base = `9A${'0'.repeat(codiceLen - 3)}1`
    if (typeof codice !== 'string' || codice.length !== codiceLen) return base
    let progX = codice.at(1) || ''
    let progN: string | number = codice.slice(2) || ''
    // NNNNN || 9NNNN --> 9A001
    if (/[0-9]/.test(progX)) return base
    // 9XNNN --> 9X999 ? 9(X+1)001 : 9XNNN + 1
    if (new RegExp(`^9{${codiceLen - 2}}$`).test(progN)) {
        progX = String.fromCharCode(progX.charCodeAt(0) + 1)
        progN = 1
    } else {
        progN = (parseInt(progN) || 0) + 1
    }
    return `9${progX}${progN.toString().padStart(codiceLen - 2, '0')}`
}

export default {
    core,
    progressivoCodiceCustom,
    services,
}
