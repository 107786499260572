import { http } from '@/scripts/core/axios'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/VFormulario'

const httpMethods = new Map([['Get', http.post]])

function getPayload(payload: Payload): Payload {
    const globalStore = useGlobalStore()
    return {
        isCountQuery: false,
        requireTotalCount: false,
        periodoDiRiferimento: { ...globalStore.state.periodoElab },
        ...payload,
    } as Payload
}

function call(urlPrefix: string) {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        descriptor.value = async (payload: Payload = {}) => {
            try {
                const _http = httpMethods.get(urlPrefix)
                return _http!(`${BASE_URL}/${urlPrefix}VFormulario`, getPayload(payload))
            } catch (error) {
                console.log(error)
            }
        }
        return descriptor
    }
}

export class APIVFormulario {
    @call('Get')
    static get(payload: Payload): any {}
}
