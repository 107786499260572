import { get } from 'lodash'

import { INDEXED_DB } from '@/constants/index'
import { getDataDb } from '@/composables/indexDB'
import { call, call1 } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/Comune'

const defaultPayload: Payload = {}

class API {
    @call1({ url: `${BASE_URL}/GetComuni`, defaultPayload })
    //valutare async oppure no da testare
    static async get<T extends Payload>(
        payload?: T,
        noHttp?: boolean,
    ): Promise<Array<Comune> | AxiosResponse<BasicResponseData<Comune>>> {
        return {
            noHttp: async (payload: T): Promise<Array<Comune>> => {
                let funzioni = null
                if (payload?.filter) {
                    let filters = JSON.parse(payload?.filter)
                    if (Array.isArray(filters) && !Array.isArray(filters[0])) filters = [filters]
                    funzioni = filters
                        .filter((_: any, idx: number) => (idx + 1) % 2 === 1) // elementi dispari di filter
                        .map((item: string) => item[2]) // nome della funzione in filter
                }
                const res: any = await getDataDb(
                    INDEXED_DB.STORES.COMUNI,
                    payload as object,
                    funzioni,
                )
                if (!res?.responseStatus?.isSuccessful) return []
                return get(res, INDEXED_DB.STORES.COMUNI.DATA_ROOT, [])?.map((comune: Comune) => {
                    if (Array.isArray(comune.comuneDato)) {
                        return { ...comune, comuneDato: comune.comuneDato[0] }
                    }
                    return comune
                })
            },
        } as any
    }
    //#US-929
    static async getComuniTipo1(params?: ComunePayload): Promise<Array<Comune>> {
        const result = (await this.get(params, true)) as Comune[]
        return result
    }

    static async getComuniTipo2(params?: ComunePayload) {
        const comuni = (await this.get(params, true)) as Comune[]

        return comuni.filter((val: any) => val.comuneDato?.codExComune == null)
    }

    static async getNazioni(params?: ComunePayload, onlyUniemens: boolean = false) {
        const comuni = (await this.get(params, true)) as Comune[]

        let nazioni = comuni.filter(val => {
            return val.isNazione
        })

        if (onlyUniemens) {
            return nazioni
                .filter(el => el?.comuneNazioneList?.[0]?.cittadinanzaUnimens != null)
                .map(item => {
                    return {
                        descrizione: item.descrizione,
                        cittadinanzaUnimens: item.comuneNazioneList?.[0].cittadinanzaUnimens,
                    }
                })
        }

        return nazioni
    }

    static async getLocalitaFiscale(params?: ComunePayload) {
        const comuni = (await this.get(params)) as Comune[]

        return comuni.filter((val: any) => {
            return ['B', 'C', 'D'].includes(val.comuneDato?.codExComune)
        })
    }
    static async getStatoEstero(params?: ComunePayload) {
        const comuni = (await this.get(params)) as Comune[]
        let statoEstero = comuni.filter(val => val.isNazione)
        let ris: { id: number; codice: string; descrizione: string }[] = []
        for (let i = 0; i < statoEstero.length; i++) {
            if (
                statoEstero?.[i]?.comuneNazioneList?.[0]?.cittadinanza770?.length //devo prendere solo quelli con codiceCittadinzna diversi da zero
            ) {
                const nazione = {
                    id: statoEstero?.[i].comuneNazioneList?.[0]?.id ?? 0,
                    codice: statoEstero?.[i]?.comuneNazioneList?.[0].cittadinanza770 ?? '', //statoEstero?.[i].comuneNazioneList[0]?.cittadinanza770,
                    descrizione: statoEstero?.[i].descrizione,
                }
                if (!nazione.id) return

                ris.push(nazione)
            }
        }

        return ris.sort((a, b) => +a.codice - +b.codice)
    }

    @call({ url: `${BASE_URL}/SaveComune` })
    static async save(
        payload: SaveComunePayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveComuneResponsePayload>> | null> {
        return null
    }
}

export default {
    get: API.get<Payload>,
    getComuniTipo1: API.getComuniTipo1,
    getComuniTipo2: API.getComuniTipo2,
    getLocalitaFiscale: API.getLocalitaFiscale,
    getStatoEstero: API.getStatoEstero,
    getNazioni: API.getNazioni,
    save: API.save,
}
