import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'nettoCostoLordo',
    ICON: 'fa-regular fa-wrench',
    TITLE: 'N.L.C.',
    CODVOCEMENU: '000204', //FALSO, CORRISPONDE A COLLEGAMENTO DIPTE - COLLABORATORE , da sostituire con quello corretto
    CODPERMESSO: ['ANAG_DITTA', 'ANAG_DIPTE', 'ANAG_COLL'],
}

const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: 'Utility - Netto Lordo Costo:Lavoratori - Utility - Utility - Paghe',
        LABEL: 'Netto - Lordo - Costo: Lavoratori',
        PATH: 'utility/nettoLordoCosto',
        NAME: 'nlcDipendenti',
        TAB: { ...tabPropsDefault, TITLE: 'N.L.C.' },
    },
    NCL: {
        TITLE: 'Utility - Netto Lordo Costo:Lavoratori - Utility - Utility - Paghe',
        LABEL: 'Netto - Lordo - Costo: Lavoratori',
        PATH: 'NLC',
        NAME: 'N.L.C.',
        TAB: { ...tabPropsDefault, TITLE: 'N.L.C.' },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: true, //TODO: SE SERVE USARE IMPLEMENTARE PROPSFN
            component: pagheViews.utility.nettoLordoCosto.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO?.[0] ?? '',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                idPaginaMsg: 'NLC00',
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.NCL.LABEL,
                    path: SETTINGS.NCL.PATH,
                    name: SETTINGS.NCL.NAME,
                    tab: SETTINGS.NCL.TAB,
                    component: pagheViews.utility.nettoLordoCosto.childs.nettoLordoCosto,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO?.[0] ?? '',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'NLC00',
                    },
                },
            ],
        },
    ],
}
