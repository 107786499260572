import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'
const BASE_URL = '/DipteRapporto'

class API {
    @call({ url: `${BASE_URL}/GetDipendentiRapporto` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DipteRapporto>> | null> {
        return null
    }
}

export default {
    get: API.get,
}
