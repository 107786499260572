import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'

const BASE_URL = '/DipteDipteSindacatoENettoConcordato'

class API {
    @call({ url: `${BASE_URL}/GetDipteSindacatoENettoConcordato` })
    static async getDipteSindacatoENettoConcordato(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteSindENettoC>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveVociFisse` })
    static async saveVociFisse(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteVoceFissa>> | null> {
        return null
    }
}

export default {
    getDipteSindacatoENettoConcordato: API.getDipteSindacatoENettoConcordato,
    saveVociFisse: API.saveVociFisse,
}
