import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/DiptePrestito'
class API {
    @call({ url: `${BASE_URL}/GetDiptePrestito` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DiptePrestito>> | null> {
        return null
    }
}

export default {
    get: API.get,
}
