import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'

class API {
    @call({ url: `${BASE_URL_DIPTE}/getDipteRapportoLavoro` })
    static async get(
        payload: DipteRapportoLavoroPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteRapportoLavoroResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteRapportoLavoro` })
    static async saveDipteRapportoLavoro(
        payload: SaveDipteRapportoLavoroPayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteRapporto>> | null> {
        return null
    }
}

export default {
    get: API.get,
    saveDipteRapportoLavoro: API.saveDipteRapportoLavoro,
}
