import { AxiosResponse } from 'axios'

import { call } from '@/scripts/core/axios'

const BASE_URL = '/movimenti'

const defaultPayload: Payload = {
    requireTotalCount: false,
    isCountQuery: false,
}

class API {
    @call({ url: `${BASE_URL}/GetMovimenti`, defaultPayload })
    static async get(
        payload: GetMovimenti,
    ): Promise<AxiosResponse<BasicResponsePayload<MovimentiCalendario>> | null> {
        return null
    }
}

export default {
    get: API.get,
}
