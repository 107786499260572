import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'
const BASE_URL = '/DipteRetribuzione'
class API {
    @call({ url: `${BASE_URL_DIPTE}/GetDipteRetribuzione` })
    static async getDipteRetribuzione(
        payload: DipteRetribuzionePayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteRetribuzioneResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteRetribuzione` })
    static async saveDipteRetribuzione(
        payload: saveDipteRetribuzionePayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteRetribuzione>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetDipteRetribuzione` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DipteRetribuzione>> | null> {
        return null
    }
}

export default {
    get: API.get,
    getDipteRetribuzione: API.getDipteRetribuzione,
    saveDipteRetribuzione: API.saveDipteRetribuzione,
}
