import { useGlobalStore } from '@/stores'
import contrattoViews from '@/modules/paghe/views/tabelle/contratto'
import { ContrattoEntity } from '@/modules/paghe/entities/contratto'

const tabPropsDefault: TabProps = {
    GROUP: 'Contratto',
    ICON: 'fa-regular fa-file-contract',
    TITLE: 'Contratti',
    CODVOCEMENU: '000026',
    CODPERMESSO: ['TABELLE_SP', 'TABELLE_STUDIO'], //DOVREBBE ESSERE UN ARRAY
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        ...route.params,
        codice: String(route.params?.codice),
        year: Number(route.params?.year ?? useGlobalStore().state.periodoElab!.year),
        month: Number(route.params?.month ?? useGlobalStore().state.periodoElab!.month),
        attualeCheck: Number(route.params?.attualeCheck ?? 1),
        //allValidate: Number(route.params?.allValidate ?? 0),
    }
}

export const SETTINGS = {
    ELENCO: {
        TITLE: 'Tabelle di domino - Gestione - Elenco contratti',
        LABEL: 'Elenco contratti',
        PATH: 'contratto/elenco/:year/:month',
        NAME: 'ContrattoElenco',
        TAB: { ...tabPropsDefault },
    },
    DETTAGLIO: {
        TITLE: 'Tabelle di domino - Gestione - Dettaglio contratto',
        LABEL: 'Dettaglio contratto',
        PATH: 'contratto/dettaglio/:year/:month/:codice/:mod', //codice o id ?
        NAME: 'ContrattoDettaglio',
        TAB: { ...tabPropsDefault },
    },
    //Children
    GENERALE: {
        TITLE: null,
        LABEL: 'Generale',
        PATH: 'generale',
        NAME: 'ContrattoGenerale',
        TAB: { ...tabPropsDefault, TITLE: 'Contratto Generale' },
    },
    SUDDIVISIONI: {
        TITLE: null,
        LABEL: 'Suddivisioni contr./terr.',
        PATH: 'sudd',
        NAME: 'ContrattoSuddContrTerr',
        TAB: { ...tabPropsDefault },
    },
    ELEMENTI_PAGA: {
        TITLE: null,
        LABEL: 'Definizione elementi paga',
        PATH: 'elementiPaga',
        NAME: 'ContrattoElementiPaga',
        TAB: { ...tabPropsDefault },
    },
    DIVISORI_ORARIO: {
        TITLE: null,
        LABEL: 'Divisori / Orario',
        PATH: 'divOra',
        NAME: 'ContrattoDivOra',
        TAB: { ...tabPropsDefault },
    },
    STRAORDINARI_MAGGIORAZIONI: {
        TITLE: null,
        LABEL: 'Straordinari / Maggiorazioni',
        PATH: 'straordinari',
        NAME: 'ContrattoStraMagg',
        TAB: { ...tabPropsDefault },
    },
    FESTIVITA: {
        TITLE: null,
        LABEL: 'Festività',
        PATH: 'festivita',
        NAME: 'ContrattoFestivita',
        TAB: { ...tabPropsDefault },
    },
    FEEXFESTROL: {
        TITLE: null,
        LABEL: 'Ferie - Ex.Festività - Rol',
        PATH: 'ferieFestRol',
        NAME: 'ContrattoFerieExFestRol',
        TAB: { ...tabPropsDefault },
    },
    MALATTIA: {
        TITLE: null,
        LABEL: 'Malattia',
        PATH: 'malattia',
        NAME: 'ContrattoMalattia',
        TAB: { ...tabPropsDefault },
    },
    ISTITUTI_CONTRATTUALI: {
        TITLE: null,
        LABEL: 'Istituti Contrattuali',
        PATH: 'istitutiContrattuali/:codIstituto?',
        NAME: 'ContrattoIstitutContrattuali',
        TAB: { ...tabPropsDefault },
    },
    MENSILITA_AGGIUNTIVE: {
        TITLE: null,
        LABEL: 'Mensilità aggiuntive',
        PATH: 'mensAggiuntive/:codMensilita?',
        NAME: 'ContrattoMensilitaAggiuntive',
        TAB: { ...tabPropsDefault },
    },
    INFORTUNIO: {
        TITLE: null,
        LABEL: 'Infortunio',
        PATH: 'infortunio',
        NAME: 'ContrattoInfortunio',
        TAB: { ...tabPropsDefault },
    },
    MATERNITA: {
        TITLE: null,
        LABEL: 'Maternità',
        PATH: 'maternita',
        NAME: 'ContrattoMaternita',
        TAB: { ...tabPropsDefault },
    },
    UNA_TANTUM: {
        TITLE: null,
        LABEL: 'Una tantum',
        PATH: 'unaTantum',
        NAME: 'ContrattoUnaTantum',
        TAB: { ...tabPropsDefault },
    },
} as const

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO.PATH,
            name: SETTINGS.ELENCO.NAME,
            component: contrattoViews.Elenco,
            meta: {
                title: SETTINGS.ELENCO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                idPaginaMsg: 'TCO00',
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    //allValidate: 0,
                },
            },
            props: propsFn,
        },
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: contrattoViews.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                // defaultProps: {
                //     year: () => useGlobalStore().state.periodoElab!.year,
                //     month: () => useGlobalStore().state.periodoElab!.month,
                //     attualeCheck: 1,
                //     allValidate: 0,
                // },
                props: propsFn,
            },
            props: propsFn,
            children: [
                {
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    component: contrattoViews.dettaglio.childs.Generale,
                    label: SETTINGS.GENERALE.LABEL,
                    tab: SETTINGS.GENERALE.TAB,
                    meta: {
                        title: SETTINGS.GENERALE.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO01',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: 0,
                },
                {
                    path: SETTINGS.SUDDIVISIONI.PATH,
                    name: SETTINGS.SUDDIVISIONI.NAME,
                    component: contrattoViews.dettaglio.childs.SuddContrTerr,
                    label: SETTINGS.SUDDIVISIONI.LABEL,
                    tab: SETTINGS.SUDDIVISIONI.TAB,
                    entity: [ContrattoEntity.Suddivisione],
                    meta: {
                        title: SETTINGS.SUDDIVISIONI.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO02',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.ELEMENTI_PAGA.PATH,
                    name: SETTINGS.ELEMENTI_PAGA.NAME,
                    component: contrattoViews.dettaglio.childs.ElementiPaga,
                    label: SETTINGS.ELEMENTI_PAGA.LABEL,
                    tab: SETTINGS.ELEMENTI_PAGA.TAB,
                    entity: [ContrattoEntity.ElePaga],
                    meta: {
                        title: SETTINGS.ELEMENTI_PAGA.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO03',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.DIVISORI_ORARIO.PATH,
                    name: SETTINGS.DIVISORI_ORARIO.NAME,
                    component: contrattoViews.dettaglio.childs.DivisoriOrario,
                    label: SETTINGS.DIVISORI_ORARIO.LABEL,
                    tab: SETTINGS.DIVISORI_ORARIO.TAB,
                    entity: [ContrattoEntity.Divisore],
                    meta: {
                        title: SETTINGS.DIVISORI_ORARIO.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO04',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.STRAORDINARI_MAGGIORAZIONI.PATH,
                    name: SETTINGS.STRAORDINARI_MAGGIORAZIONI.NAME,
                    component: contrattoViews.dettaglio.childs.Straordinario,
                    label: SETTINGS.STRAORDINARI_MAGGIORAZIONI.LABEL,
                    tab: SETTINGS.STRAORDINARI_MAGGIORAZIONI.TAB,
                    entity: [ContrattoEntity.Straordinario],
                    meta: {
                        title: SETTINGS.STRAORDINARI_MAGGIORAZIONI.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO05',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.FESTIVITA.PATH,
                    name: SETTINGS.FESTIVITA.NAME,
                    component: contrattoViews.dettaglio.childs.Festivita,
                    label: SETTINGS.FESTIVITA.LABEL,
                    tab: SETTINGS.FESTIVITA.TAB,
                    entity: [ContrattoEntity.Festivita],
                    meta: {
                        title: SETTINGS.FESTIVITA.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO06',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.ISTITUTI_CONTRATTUALI.PATH,
                    name: SETTINGS.ISTITUTI_CONTRATTUALI.NAME,
                    component: contrattoViews.dettaglio.childs.IstitutiContrattuali,
                    label: SETTINGS.ISTITUTI_CONTRATTUALI.LABEL,
                    tab: SETTINGS.ISTITUTI_CONTRATTUALI.TAB,
                    entity: [ContrattoEntity.IstitutoContr],
                    meta: {
                        title: SETTINGS.ISTITUTI_CONTRATTUALI.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO07',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: route => {
                        const baseProps = propsFn(route)
                        return { ...baseProps, codIstituto: route.params?.codIstituto ?? '21' }
                    },
                    orderWizard: null,
                },
                {
                    path: SETTINGS.MENSILITA_AGGIUNTIVE.PATH,
                    name: SETTINGS.MENSILITA_AGGIUNTIVE.NAME,
                    component: contrattoViews.dettaglio.childs.MensilitaAggiuntive,
                    label: SETTINGS.MENSILITA_AGGIUNTIVE.LABEL,
                    tab: SETTINGS.MENSILITA_AGGIUNTIVE.TAB,
                    entity: [ContrattoEntity.MensAgg],
                    meta: {
                        title: SETTINGS.MENSILITA_AGGIUNTIVE.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO08',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: route => {
                        const baseProps = propsFn(route)
                        return { ...baseProps, codMensilita: route.params?.codMensilita ?? '13' }
                    },
                    orderWizard: null,
                },
                {
                    path: SETTINGS.FEEXFESTROL.PATH,
                    name: SETTINGS.FEEXFESTROL.NAME,
                    component: contrattoViews.dettaglio.childs.FeExFestRol,
                    label: SETTINGS.FEEXFESTROL.LABEL,
                    tab: SETTINGS.FEEXFESTROL.TAB,
                    entity: [ContrattoEntity.FeExFestRol],
                    meta: {
                        title: SETTINGS.FEEXFESTROL.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO09',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.MALATTIA.PATH,
                    name: SETTINGS.MALATTIA.NAME,
                    component: contrattoViews.dettaglio.childs.Malattia,
                    label: SETTINGS.MALATTIA.LABEL,
                    tab: SETTINGS.MALATTIA.TAB,
                    entity: [ContrattoEntity.Malattia],
                    meta: {
                        title: SETTINGS.MALATTIA.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO10',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.INFORTUNIO.PATH,
                    name: SETTINGS.INFORTUNIO.NAME,
                    component: contrattoViews.dettaglio.childs.Infortunio,
                    label: SETTINGS.INFORTUNIO.LABEL,
                    tab: SETTINGS.INFORTUNIO.TAB,
                    entity: [ContrattoEntity.Infortunio],
                    meta: {
                        title: SETTINGS.INFORTUNIO.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO11',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.MATERNITA.PATH,
                    name: SETTINGS.MATERNITA.NAME,
                    component: contrattoViews.dettaglio.childs.Maternita,
                    label: SETTINGS.MATERNITA.LABEL,
                    tab: SETTINGS.MATERNITA.TAB,
                    entity: [ContrattoEntity.Maternita],
                    meta: {
                        title: SETTINGS.MATERNITA.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO12',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
                {
                    path: SETTINGS.UNA_TANTUM.PATH,
                    name: SETTINGS.UNA_TANTUM.NAME,
                    component: contrattoViews.dettaglio.childs.UnaTantum,
                    label: SETTINGS.UNA_TANTUM.LABEL,
                    tab: SETTINGS.UNA_TANTUM.TAB,
                    entity: [ContrattoEntity.UnaTantum],
                    meta: {
                        title: SETTINGS.UNA_TANTUM.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TCO18',
                        group: SETTINGS.DETTAGLIO.NAME,
                    },
                    props: propsFn,
                    orderWizard: null,
                },
            ],
        },
    ],
}
