import type { AxiosResponse } from 'axios'

import { call } from '@/scripts/core/axios'

const BASE_URL = '/Elaborazione'

class API {
    @call({ url: `${BASE_URL}/GetContatoriElaborazione` })
    static async getContatoriElaborazione(
        payload: GetContatoriElaborazione,
    ): Promise<AxiosResponse<BasicResponsePayload<ContatoriElaborazione>> | null> {
        return null
    }
}

export default {
    getContatoriElaborazione: API.getContatoriElaborazione,
}
