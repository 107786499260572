import { call } from '@/scripts/core/axios'

class API {
    @call({ url: '/Studio/GetStudi' })
    static get<T>(payload: T): any {}

    @call({ url: '/StudioDettaglio/GetStudiDefCampoPersonalizzato' })
    static getCampiPersonalizzati<T>(payload: T): any {}

    @call({ url: '/StudioVoce/GetStudiVoce' })
    static getDescrizioneVoci<T>(payload: T): any {}

    @call({ url: '/Studio/SaveStudioDefCampoPersonalizzato' })
    static saveCampiPersonalizzati<T>(payload: T): any {}

    @call({ url: '/StudioVoce/SaveStudioVoce' })
    static saveDescrizioneVoci<T>(payload: T): any {}

    @call({ url: '/Studio/UpdateStudio', method: 'put' })
    static update<T>(payload: T): any {}
}

export default {
    get: API.get<Payload>,
    getCampiPersonalizzati: API.getCampiPersonalizzati<Payload>,
    getDescrizioneVoci: API.getDescrizioneVoci<Payload>,
    saveCampiPersonalizzati: API.saveCampiPersonalizzati<Payload>,
    saveDescrizioneVoci: API.saveDescrizioneVoci<Payload>,
    update: API.update<Payload>,
}
