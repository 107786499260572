import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'

class API {
    @call({ url: `${BASE_URL_DIPTE}/SaveDipteAltroReddito` })
    static async save(
        payload: SaveDipteAltroRedditoPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveDipteAltroRedditoResponse>> | null> {
        return null
    }
}

export default {
    save: API.save,
}
