import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'
import { BASE_URL_DITTA } from './ditta'

const BASE_URL_CANTIERE = '/DittaCantiere'

class API {
    @call({ url: `${BASE_URL_CANTIERE}/GetDitteCantiere` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaCantiere>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_DITTA}/SaveDittaCantiere` })
    static async save(
        payload: SaveDittaCantiere,
    ): Promise<AxiosResponse<BasicResponseData<DittaCantiere>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
