import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

export const BASE_URL_RAPPRESENTANTE = '/DittaRappresentante'

class API {
    @call({ url: `${BASE_URL_RAPPRESENTANTE}/GetDitteRappresentante` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DittaRappresentante>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_RAPPRESENTANTE}/CreateDittaRappresentante` })
    static async save(
        payload: CreateDittaRappresentante,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaRappresentante & DiarioLog>> | null> {
        return null
    }
    @call({ url: `${BASE_URL_RAPPRESENTANTE}/UpdateDittaRappresentante`, method: 'put' })
    static async update(
        payload: UpdateDittaRappresentante,
    ): Promise<AxiosResponse<BasicResponsePayload<DittaRappresentante & DiarioLog>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
    update: API.update,
}
