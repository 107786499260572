import { RouteLocation } from 'vue-router'

import pagheViews from '@paghe/views'
import utyDate from '@/scripts/services/date'

const tabPropsDefault: TabProps = {
    CODPERMESSO: 'COMPENSO_COLL',
    CODVOCEMENU: '000016',
    GROUP: 'CompensiCollaboratori',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'COMPENSI COLLABORATORI',
}

const SETTINGS = Object.freeze({
    ELENCO_DITTE: {
        NAME: 'CompensiCollaboratoriElencoDitte',
        PATH: 'movimenti/compensi-collaboratori/elenco-ditte',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Movimenti - Compensi Collaboratori',
    },
    ELENCO_COLLABORATORI: {
        NAME: 'CompensiCollaboratoriElencoCollaboratori',
        PATH: 'movimenti/compensi-collaboratori/elenco-collaboratori/:periodo/:tipoElab/:idDitta/:idUP',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Movimenti - Compensi Collaboratori',
    },
    DETTAGLIO: {
        NAME: 'CompensiCollaboratoriDettaglio',
        PATH: 'movimenti/compensi-collaboratori/dettaglio/:periodo/:tipoElab/:idDitta/:idUP/:id',
        TITLE: 'Paghe - Movimenti - Compensi Collaboratori',
    },
    DETTAGLIO_COMPENSI: {
        LABEL: 'Compensi collaboratore',
        NAME: 'CompensiCollaboratoriDettaglioCompensi',
        PATH: 'compensi',
        TAB: { ...tabPropsDefault },
    },
})

function parseElencoCollaboratoriProps(route: RouteLocation) {
    return {
        idDitta: Number(route.params.idDitta),
        idUP: Number(route.params.idUP),
        periodo: utyDate.toObject(route.params.periodo + '01'),
        tipoElab: String(route.params.tipoElab),
    }
}

function parseDettaglioCollaboratoreProps(route: RouteLocation) {
    return {
        ...parseElencoCollaboratoriProps(route),
        id: Number(route.params.id),
    }
}

export default {
    SETTINGS,
    routes: [
        {
            component: pagheViews.movimenti.compensiCollaboratori.ElencoDitte,
            meta: {
                idPaginaMsg: 'MOVCOLL',
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                title: SETTINGS.ELENCO_DITTE.TITLE,
            },
            name: SETTINGS.ELENCO_DITTE.NAME,
            path: SETTINGS.ELENCO_DITTE.PATH,
        },
        {
            component: pagheViews.movimenti.compensiCollaboratori.ElencoCollaboratori,
            meta: {
                idPaginaMsg: 'MOVCOLL',
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                title: SETTINGS.ELENCO_COLLABORATORI.TITLE,
            },
            name: SETTINGS.ELENCO_COLLABORATORI.NAME,
            path: SETTINGS.ELENCO_COLLABORATORI.PATH,
            props: parseElencoCollaboratoriProps,
        },
        {
            component: pagheViews.movimenti.compensiCollaboratori.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            name: SETTINGS.DETTAGLIO.NAME,
            path: SETTINGS.DETTAGLIO.PATH,
            props: parseDettaglioCollaboratoreProps,
            children: [
                {
                    component: pagheViews.movimenti.compensiCollaboratori.childs.Compensi,
                    label: SETTINGS.DETTAGLIO_COMPENSI.LABEL,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    name: SETTINGS.DETTAGLIO_COMPENSI.NAME,
                    orderWizard: 0,
                    path: SETTINGS.DETTAGLIO_COMPENSI.PATH,
                    props: parseDettaglioCollaboratoreProps,
                    tab: SETTINGS.DETTAGLIO_COMPENSI.TAB,
                },
            ],
        },
    ],
}
