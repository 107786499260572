import codiceUnilav from './codice-unilav'
import elaborazione from './elaborazione'
import formule from './formule'
import gruppoRisultato from './gruppo-risultato'
import minimaleMassimale from './minimaleMassimale'
import periodoElaborazione from './periodo-elaborazione'
import routineCalcolo from './routine-calcolo'
import scadenza from './scadenza'
import scadenzaCodice from './scadenza-codice'

export default {
    codiceUnilav,
    elaborazione,
    formule,
    gruppoRisultato,
    minimaleMassimale,
    periodoElaborazione,
    routineCalcolo,
    scadenza,
    scadenzaCodice,
}
