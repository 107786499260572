import { AxiosResponse } from 'axios'

import { call } from '@/scripts/core/axios'

const BASE_URL = '/movimenti'

class API {
    @call({ url: `${BASE_URL}/SaveMensilitaAggiuntive` })
    static async save(
        payload: SaveMensilitaAggiuntive,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveMensilitaAggiuntive>> | null> {
        return null
    }
}

export default {
    save: API.save,
}
