import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'
import { BASE_URL_DIPTE } from './dipte'

const BASE_URL = '/DipteOpzione'

class API {
    @call({ url: `${BASE_URL}/GetDipteOpzione` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DipteOpzione>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/UpdateDipteOpzione`, method: 'put' })
    static async update(
        payload: UpdateDipteOpzionePayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteOpzione>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/CreateDipteOpzione` })
    static async create(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<DipteOpzione>> | null> {
        return null
    }

    @call({ url: `${BASE_URL_DIPTE}/SaveDipteOpzione` })
    static async saveDipteOpzione(
        payload: saveDipteOpzionePayload,
    ): Promise<AxiosResponse<BasicResponseData<DipteOpzione>> | null> {
        return null
    }
}

export default {
    get: API.get,
    update: API.update,
    create: API.create,
    saveDipteOpzione: API.saveDipteOpzione,
}
