import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VInailSconto'

class API {
    @call({ url: `${BASE_URL}/GetVInailSconto` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<VInailSconto>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVInailSconto` })
    static async save(
        payload: SaveVInailSconto,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveVInailSconto>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
