import type { Ref } from 'vue'
import { computed, reactive, ref } from 'vue'
import { defineStore } from 'pinia'
import { createPromiseDialog } from 'vue-promise-dialogs'

import SceltaOperatore from '@/components/SceltaOperatore.vue'
import { APIAuth } from '@/api'
import { useGlobalStore } from '@/stores'
import { setDataDb } from '@/composables/indexDB'

type AccessoDitta = {
    codDitta: string
    codUP: string | null
    id: number
    idOperatore: number
    isEscludi: boolean
}

type PagePermission = {
    codPermesso: string
    codTipoPermesso: CodTipoPermesso
}

export type Menu = {
    codAutoMenu: 'A' | 'D' | 'P'
    codVoceMenu: string
    descrizione: string
    vociFiglie?: Menu
}

export type OperatoreFE = {
    attivo: boolean
    codice: string
    id: number
    userName: string
}

export default defineStore(
    'profile',
    () => {
        // state
        const codTipoOperatore: Ref<CodTipoOperatore> = ref('S')
        const idStudio = ref(0)
        const menu: Ref<Menu[]> = ref([])
        const operatoreAccessoDittaList: Ref<AccessoDitta[]> = ref([])
        const operatori: Ref<OperatoreFE[]> = ref([])
        const pagePermissions: Ref<PagePermission[]> = ref([])
        const user = reactive({})

        // getters
        const operatoreAttivoStr = computed(() => {
            const operatore = operatori.value.find(operatore => operatore.attivo)
            return operatore ? `${operatore?.codice} - ${operatore?.userName}` : ''
        })

        // actions
        function _findPagePermission(codPermesso: string) {
            return pagePermissions.value.findIndex(item => item.codPermesso === codPermesso)
        }

        function _getPermissionTypeByIndex(index: number) {
            return pagePermissions.value.at(index)?.codTipoPermesso
        }

        function _setProfilePermissions(operatore: Operatore) {
            if (operatore?.profiloPermesso?.profiloPermessoElementoList) {
                pagePermissions.value = operatore.profiloPermesso.profiloPermessoElementoList.map(
                    item => ({
                        codPermesso: item.codPermesso!,
                        codTipoPermesso: item.codTipoPermesso!,
                    }),
                )
            }
            if (operatore.voci) {
                menu.value = operatore.voci.filter(item =>
                    ['A', 'D'].includes(item.codAutoMenu!),
                ) as Menu[]
            }
            if (operatore.operatoreAccessoDittaList) {
                operatoreAccessoDittaList.value =
                    operatore.operatoreAccessoDittaList as AccessoDitta[]
            }
            codTipoOperatore.value = operatore.codTipoOperatore!
            getCodiceDittaList()
            idStudio.value = operatore.idStudio!
        }

        function getCodiceDittaList() {
            let listaDitta = operatoreAccessoDittaList.value.filter(el => {
                return el.isEscludi
            })
            let listaCodici = listaDitta.map(el => el.codDitta)

            return listaCodici
        }

        function getOperatore() {
            const codice = operatori.value.find(item => item.attivo)?.codice
            return operatori.value.find(item => item.codice === codice)
        }

        function getPagePermissions() {
            return JSON.parse(JSON.stringify(pagePermissions.value))
        }

        function getPermissionType(codPermesso: string | string[]) {
            if (codPermesso === 'ALL') return 'A'
            if (Array.isArray(codPermesso)) {
                return codPermesso.reduce((acc, val) => {
                    // aggiorna l'oggetto e poi lo restituisce (usa il comma operator)
                    return (acc[val] = _getPermissionTypeByIndex(_findPagePermission(val))), acc
                }, {})
            }
            return _getPermissionTypeByIndex(_findPagePermission(codPermesso))
        }

        async function getProfile() {
            const globalStore = useGlobalStore()
            let response = await APIAuth.get()
            if (response?.status === 406) {
                const endpoint =
                    location.origin === import.meta.env.VITE_LOGIN_ENDPOINT
                        ? import.meta.env.VITE_LOGIN_ENDPOINT_MP
                        : import.meta.env.VITE_LOGIN_ENDPOINT
                if (endpoint) location.replace(endpoint)
            } else {
                if (response?.data.responseStatus.isSuccessful) {
                    _setProfilePermissions(response.data.payload)
                    globalStore.state.codOperatore = response.data.payload.codiceOperatore
                    globalStore.state.codService = response.data.payload.codDistributore
                    globalStore.state.codTeamAzienda = response.data.payload.codTeamAzienda
                    globalStore.state.codStudio =
                        response.data.payload.codiceDistributoreStudio.slice(3)
                    if (['A', 'E'].includes(codTipoOperatore.value)) {
                        await setDataDb(['VCodDes'])
                    } else {
                        await setDataDb()
                    }
                    const ope = operatori.value.find(
                        item => item.codice === response.data.payload.codiceOperatore,
                    )
                    if (ope) {
                        ope.id = response.data.payload.idOperatore
                        ope.attivo = true
                    } else {
                        operatori.value.push({
                            attivo: true,
                            codice: response.data.payload.codiceOperatore,
                            userName: response.data.payload.userName,
                            id: response.data.payload.idOperatore,
                        })
                    }
                    return true
                } else {
                    response?.data.data.forEach((item: OperatoreMulti) => {
                        operatori.value.push({
                            attivo: false,
                            codice: item.codice!,
                            id: item.id!,
                            userName: item.userName!,
                        })
                    })
                    const operatore = await createPromiseDialog<undefined, OperatoreFE>(
                        SceltaOperatore,
                    )(undefined)
                    if (operatore) {
                        return await getProfile()
                    }
                }
            }
            return false
        }

        return {
            codTipoOperatore,
            getCodiceDittaList,
            getOperatore,
            getPagePermissions,
            getPermissionType,
            getProfile,
            idStudio,
            menu,
            operatoreAccessoDittaList,
            operatoreAttivoStr,
            operatori,
            pagePermissions,
            user,
        }
    },
    {
        persist: true,
    },
)
