<template>
    <div class="modal-overlay">
        <div class="modal-inner">
            <h1
                id="mainNav"
                class="sr-only"
            >
                Menu di navigazione principale
            </h1>
            <div class="modal-inner-header-wrapper pt-3 pb-3">
                <div
                    class="d-flex align-items-center modal-inner-header border-bottom border-top ml-2"
                >
                    <div class="flexgrow-1">
                        <img
                            alt="Logo Essepaghe menu modal window"
                            height="35"
                            src="@/assets/logo.png"
                            title="Logo Essepaghe menu modal window"
                            width="35"
                        />
                        <span
                            class="ml-1 mr-5"
                            :style="{
                                letterSpacing: '1px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }"
                        >
                            PAGHE
                        </span>

                        <!--// elenco voci di menu di primo livello -->
                        <FirstLevelItems
                            :menu-model="menu"
                            :active-item-id="selectedMenu"
                            @show-selected-menu="selectedMenu = $event"
                        ></FirstLevelItems>

                        <!--// search -->
                        <FeaturesQuickSearch
                            @close-hmenu-modal="closeHmenuModal"
                        ></FeaturesQuickSearch>
                        <!-- <span><i class="fa-solid fa-magnifying-glass"></i></span> -->
                    </div>
                    <v-btn
                        class="ml-auto mr-3"
                        variant="plain"
                        icon="fas fa-xmark"
                        density="compact"
                        @click="closeHmenuModal"
                    />
                </div>
            </div>
            <div
                id="modal-inner-section"
                ref="modalInnerSection"
                class="modal-inner-content p-4"
                aria-labelledby="titoloSezione"
                tabindex="-1"
            >
                <h2
                    id="titoloSezione"
                    class="sr-only"
                >
                    Sezione {{ menu[selectedMenu].descrizione }}
                </h2>
                <!-- // CONTENTUO A TABS: struttura menu -->
                <OtherLevelsItems
                    :key="clickCount"
                    :menu-model="getTreeBranch"
                    @close-hmenu-modal="closeHmenuModal"
                ></OtherLevelsItems>
            </div>
            <div class="modal-footer-wrapper">
                <!-- WCAG: Bottone per impostare focus su sezione principale -->
                <button
                    class="vis-only-keyboard btn-focus-menu-section"
                    aria-label="MENU: Torna a sezione principale"
                    title="MENU: Torna a sezione principale"
                    @click="setSectionVisible()"
                >
                    <v-icon icon="fa:fas fa-circle-chevron-left"></v-icon>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import FirstLevelItems from '@/components/navigation/menu/FirstLevelItems.vue'
import OtherLevelsItems from '@/components/navigation/menu/OtherLevelItems.vue'
import FeaturesQuickSearch from '@/components/navigation/menu/FeaturesQuickSearch.vue'
import { storeToRefs } from 'pinia'
import useStorePermissions from '@paghe/stores/core/pagePermission'
import { ref } from 'vue'

export default {
    name: 'FullWidthModal',
    components: {
        FirstLevelItems,
        OtherLevelsItems,
        FeaturesQuickSearch,
    },
    props: {},
    emits: ['close-hmenu-modal'],
    setup() {
        const { menu } = storeToRefs(useStorePermissions())
        let selectedMenu = ref(0)
        let active2ndLevel = ref(0)
        const modalInnerSection = ref(null)

        return {
            menu,
            selectedMenu,
            active2ndLevel,
            modalInnerSection,
        }
    },
    data() {
        return {
            clickCount: 0,
            isDarkTheme: false,
        }
    },
    computed: {
        getTreeBranch() {
            const index = this.selectedMenu
            return this.menu[index].vociFiglie
        },
    },
    watch: {
        selectedMenu() {
            this.active2ndLevel = 0
        },
    },
    mounted() {
        // check if dark theme
        this.checkIfDarkTheme()
    },
    methods: {
        closeHmenuModal() {
            this.$emit('close-hmenu-modal')
        },
        checkIfDarkTheme() {
            if (
                localStorage.getItem('dark_theme') == true ||
                localStorage.getItem('dark_theme')?.toString() == 'true'
            ) {
                this.isDarkTheme = true
            } else {
                this.isDarkTheme = false
            }
        },
        setSectionVisible() {
            this.modalInnerSection.focus()
        },
    },
}
</script>

<style lang="scss" src="@/styles/components/navigation/menu/FullWidthModal.scss"></style>

<style lang="scss">
.vis-only-keyboard {
    margin-left: -9999px;
    position: absolute;

    &:focus-visible {
        margin-left: 0px;
        position: static;
    }
}

.modal-footer-wrapper {
    text-align: right;
    padding-right: 2rem;
}
.btn-focus-menu-section {
    &:focus {
        padding: 0.5rem;
    }
}
</style>
