import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/ViewDipteValidita'

class API {
    @call({ url: `${BASE_URL}/Get` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<ViewDipteValidita>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/GetViewDipteValidita` })
    static async getViewDipteValidita<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<ViewDipteValidita>> | null> {
        return null
    }
}

export default {
    get: API.get,
    getViewDipteValidita: API.getViewDipteValidita,
}
