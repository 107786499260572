import { AxiosResponse } from 'axios'

import { call } from '@/scripts/core/axios'

const BASE_URL = '/ArchivioFile'

const defaultPayload: Payload = {
    isNuovaValidita: true,
}

class API {
    @call({ url: `${BASE_URL}/GetArchivioFile`, defaultPayload })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<GetArchivioFile>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveArchivioFile`, defaultPayload })
    static async save<T>(payload?: T): Promise<AxiosResponse<BasicResponsePayload> | null> {
        return null
    }
}

export default {
    get: API.get<Payload>,
    save: API.save<SaveReportPayload>,
}
