import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/DipteTFRFPC'
class API {
    @call({ url: `${BASE_URL}/GetDipteTFRFPC` })
    static async get(payload: Payload): Promise<AxiosResponse<BasicResponseData<any>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveDipteTFRFPC` })
    static async save(
        payload: saveDipteRetribuzionePayload,
    ): Promise<AxiosResponse<BasicResponseData<any>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
