import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VCodiceUnilav'

class API {
    @call({ url: `${BASE_URL}/GetVCodiceUnilav` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<VCodiceUnilav>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/SaveVCodiceUnilav` })
    static async save(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveVCodiceUnilav>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
