import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000166',
    GROUP: 'exportDefinizioni',
    ICON: 'fa-regular fa-file-export',
    TITLE: 'Definizione Export',
    CODPERMESSO: ['DEF_EXPORT_SP', 'DEF_EXPORT_STUDIO'], //DOVREBBE ESSERE UN ARRAY
}
const SETTINGS = Object.freeze({
    ELENCO: {
        NAME: 'DefExportElenco',
        PATH: 'export/definizioni',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Segreteria - Definizione Export',
    },
    DETTAGLIO: {
        NAME: 'DefExportDettaglio',
        PATH: 'export/definizioni/:id/:mod',
        TITLE: 'Paghe - Segreteria - Definizione Export',
    },
    GENERALE: {
        LABEL: 'Generale',
        NAME: 'DefExportGenerale',
        PATH: 'generale',
        TAB: { ...tabPropsDefault, TITLE: 'Definizione Export' },
    },
    DATI: {
        LABEL: 'Dati',
        NAME: 'DefExportDati',
        PATH: 'dati',
        TAB: { ...tabPropsDefault, TITLE: 'Definizione Export' },
    },
    FILTRO: {
        LABEL: 'Filtro',
        NAME: 'DefExportFiltro',
        PATH: 'filtro',
        TAB: { ...tabPropsDefault, TITLE: 'Definizione Export' },
    },
    LAYOUT: {
        LABEL: 'Layout',
        NAME: 'DefExportLayout',
        PATH: 'layout',
        TAB: { ...tabPropsDefault, TITLE: 'Definizione Export' },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO.PATH,
            name: SETTINGS.ELENCO.NAME,
            component: pagheViews.exports.definizioni.Lista,
            meta: {
                title: SETTINGS.ELENCO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
        },
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: true,
            component: pagheViews.exports.definizioni.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.GENERALE.LABEL,
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    tab: SETTINGS.GENERALE.TAB,
                    component: pagheViews.exports.definizioni.childs.Generale,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 0,
                    props: true,
                },
                {
                    label: SETTINGS.DATI.LABEL,
                    path: SETTINGS.DATI.PATH,
                    name: SETTINGS.DATI.NAME,
                    tab: SETTINGS.DATI.TAB,
                    component: pagheViews.exports.definizioni.childs.Dati,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: 'DEF_EXPORT_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 1,
                    props: true,
                },
                {
                    label: SETTINGS.FILTRO.LABEL,
                    path: SETTINGS.FILTRO.PATH,
                    name: SETTINGS.FILTRO.NAME,
                    tab: SETTINGS.FILTRO.TAB,
                    component: pagheViews.exports.definizioni.childs.Filtro,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: 'DEF_EXPORT_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: null,
                    props: true,
                },
                {
                    label: SETTINGS.LAYOUT.LABEL,
                    path: SETTINGS.LAYOUT.PATH,
                    name: SETTINGS.LAYOUT.NAME,
                    tab: SETTINGS.LAYOUT.TAB,
                    component: pagheViews.exports.definizioni.childs.Layout,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: 'DEF_EXPORT_SP',
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 3,
                    props: true,
                },
            ],
        },
    ],
}
