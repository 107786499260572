import { http } from '@/scripts/core/axios'
import { useGlobalStore } from '@/stores'

const BASE_URL = '/AggTabDominio'

const payload: AggiornamentiPayload = {
    requireTotalCount: false,
    isCountQuery: false,
    codiceSblocco: null,
    isAggiornamentiEseguiti: false,
    isSoloCheckAggiornamenti: true,
}

export default class APIAggiornamenti {
    static async get(params?: AggiornamentiPayload) {
        const globalStore = useGlobalStore()
        try {
            return http.post(`${BASE_URL}/GetAggTabDominio`, {
                ...payload,
                ...{
                    periodoDiRiferimento: globalStore.state.periodoElab,
                },
                ...params,
            })
        } catch (error) {
            console.log(error)
        }
    }
}
