import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/MinimaleMassimale'

class API {
    @call({ url: `${BASE_URL}/GetMinimaleMassimale` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<MinimaleMassimale>> | null> {
        return null
    }
}

export default {
    get: API.get,
}
