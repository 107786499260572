import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/RoutineDiCalcolo'

class API {
    @call({ url: `${BASE_URL}/GetRoutineDiCalcoloNameList` })
    static async getRoutineDiCalcoloNameList<T extends GetRoutineDiCalcoloName>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<RoutineDiCalcoloName>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetRoutineDiCalcoloParametriList` })
    static async getRoutineDiCalcoloParametriList<T extends GetRoutineDiCalcoloParametri>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<RoutineDiCalcoloParametri>> | null> {
        return null
    }
}

export default {
    getRoutineDiCalcoloNameList: API.getRoutineDiCalcoloNameList,
    getRoutineDiCalcoloParametriList: API.getRoutineDiCalcoloParametriList,
}
