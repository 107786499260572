<template>
    <BaseDialog
        icon="fal fa-users-between-lines"
        show
        title="Selezionare uno degli operatori presenti in lista"
        type="info"
        @cancel="emit('resolve')"
    >
        <template #body>
            <BaseTable
                class="mx-3"
                :dt-props="dtProps"
                selection-key="codice"
                @click-row="enterRow"
            />
            <div class="d-flex justify-end">
                <v-btn
                    class="mt-3 mr-3"
                    color="tertiary"
                    flat
                    @click="emit('resolve')"
                >
                    ANNULLA
                </v-btn>
            </div>
        </template>
    </BaseDialog>
</template>

<script setup lang="ts">
import type { OperatoreFE } from '@paghe/stores/core/pagePermission'
import useStorePermissions from '@paghe/stores/core/pagePermission'
import BaseDialog from './base/BaseDialog.vue'
import BaseTable from '@/components/base/BaseTable.vue'

const storePermissions = useStorePermissions()

const dtProps = {
    headers: [
        {
            align: 'center',
            divider: true,
            key: 'codice',
            sortable: true,
            title: 'Codice',
            width: 150,
        },
        {
            align: 'start',
            divider: true,
            key: 'userName',
            sortable: true,
            title: 'Username',
        },
    ],
    items: storePermissions.operatori,
    showSelect: false,
}

const emit = defineEmits(['reject', 'resolve'])

function enterRow(item: { [key: string]: any }) {
    const operatore: any = storePermissions.operatori.find(
        (operatore: OperatoreFE) => operatore.codice === item.codice,
    )
    if (operatore) operatore.attivo = true
    emit('resolve', operatore)
}
</script>
