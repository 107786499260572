import type { SweetAlertOptions, SweetAlertResult } from 'sweetalert2'
import Swal from 'sweetalert2'
import { get } from 'lodash'

type MessageType = 'error' | 'info' | 'question' | 'success' | 'warning'

const globalStandardMessage = {
    error: 'Operazione non riuscita',
    success: 'Operazione completata con successo',
}

async function sweetFire(
    toast: boolean,
    type: MessageType,
    message?: string | null,
    title?: string | null,
    buttons?: 'ok' | 'siNo' | 'siAnnulla' | 'siNoAnnulla',
    options: SweetAlertOptions = {},
): Promise<SweetAlertResult> {
    if (title === undefined) {
        title = get(
            {
                error: 'Errore',
                info: 'Info',
                question: null,
                success: null,
                warning: 'Attenzione',
            },
            type,
        )
    }
    if (message === undefined) {
        switch (type) {
            case 'error':
                message = globalStandardMessage.error
                break
            case 'success':
                message = globalStandardMessage.success
                break
        }
    }

    let _options: SweetAlertOptions
    if (toast) {
        _options = {
            customClass: {
                popup: 'colored-toast',
            },
            html: message || undefined,
            icon: type,
            iconColor: 'white',
            position: 'top-right',
            showCloseButton: true,
            showConfirmButton: false,
            timer: message === globalStandardMessage.success ? 3000 : 5000,
            timerProgressBar: true,
            title: title || undefined,
            toast: true,
            width: 'fit-content',
        }
    } else {
        _options = {
            allowOutsideClick: false,
            cancelButtonText: 'ANNULLA',
            confirmButtonColor: `var(--bg-button-${type})`,
            confirmButtonText: buttons?.includes('si') ? 'SI' : 'OK',
            denyButtonColor: '#546E7A',
            denyButtonText: 'NO',
            html: message || undefined,
            icon: type,
            iconColor: `var(--bg-button-${type})`,
            showCancelButton: buttons?.includes('Annulla'),
            showDenyButton: buttons?.includes('No'),
            title: title || undefined,
            toast: false,
        }
    }

    return await Swal.fire(Object.assign(_options, options))
}

function showMessage(
    this: { toast: boolean; type: MessageType },
    message?: string | null,
    title?: string | null,
    buttons?: 'ok' | 'siNo' | 'siAnnulla' | 'siNoAnnulla',
    options?: SweetAlertOptions,
) {
    return sweetFire(this.toast, this.type, message, title, buttons, options)
}

//#region PREDEFINITE BOX
async function _saveAndClose(isError: boolean) {
    return await showMessage.bind({ toast: false, type: 'warning' })(
        isError
            ? 'Rilevata la presenza di errori bloccanti, impossibile salvare i dati.' +
                  '<br><br>CONTINUA per procedere senza salvare' +
                  '<br>ANNULLA per rimanere nella pagina'
            : 'Sono state fatte delle variazioni, vuoi salvare?',
        undefined,
        isError ? 'siAnnulla' : 'siNoAnnulla',
        {
            confirmButtonText: isError ? 'CONTINUA' : 'SI',
        },
    )
}
//#endregion PREDEFINITE BOX

//#region PREDEFINITE TOAST
function _beValidationError() {
    return showMessage.bind({ toast: true, type: 'error' })(
        'Correggere i campi evidenziati in rosso e riprovare',
        'Operazione non riuscita',
    )
}

function _operationStarted() {
    return showMessage.bind({ toast: true, type: 'success' })(
        "Lo stato dell'elaborazione sarà disponibile in <i>Schedulazione attività</i>",
        'Presa in carico operazione',
    )
}
//#endregion PREDEFINITE TOAST

export default {
    box: {
        _saveAndClose,
        error: showMessage.bind({ toast: false, type: 'error' }),
        info: showMessage.bind({ toast: false, type: 'info' }),
        question: showMessage.bind({ toast: false, type: 'question' }),
        success: showMessage.bind({ toast: false, type: 'success' }),
        warning: showMessage.bind({ toast: false, type: 'warning' }),
    },
    toast: {
        _beValidationError,
        _operationStarted,
        error: showMessage.bind({ toast: true, type: 'error' }),
        info: showMessage.bind({ toast: true, type: 'info' }),
        success: showMessage.bind({ toast: true, type: 'success' }),
        warning: showMessage.bind({ toast: true, type: 'warning' }),
    },
}
