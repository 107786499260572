import { AxiosResponse } from 'axios'

import { call } from '@/scripts/core/axios'

const BASE_URL = '/LogMessage'

class API {
    @call({ url: `${BASE_URL}/GetLogMessage` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<GetLogMessage>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/CreatePdfFromLogMessage` })
    static async getPdf(
        payload: CreatePdfFromLogMessage,
    ): Promise<AxiosResponse<BasicResponsePayload<PdfFromLogMessage>> | null> {
        return null
    }
}

export default {
    get: API.get,
    getPdf: API.getPdf,
}
