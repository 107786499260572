import { pagheAPI } from '@/api'
import { makeIdPropsDefault, makePeriodoPropsDefault } from '@/composables'
import { GLOBALS } from '@/constants'
import type { NomeTabellaAttivaValue } from '@/modules/paghe/entities/ditte'
import { FooterActionsEnum } from '@/typescript/Enums'
import { get } from 'lodash'
//TODO:DEFINIRE TIPO PROPS QUI O IN TYPES
export const baseEmits = ['goNextPage', 'listNavigation', 'refreshPage']

export interface BaseEmits {
    //goNextPage: [id: number]
    //listNavigation: [value: string]
    //refreshPage: []
    (e: 'goNextPage', value?: number): void
    (e: 'listNavigation', value: FooterActionsEnum): void
    (e: 'refreshPage'): void
}

export const baseProps = {
    ...makePeriodoPropsDefault(),
    ...makeIdPropsDefault(),
    idPadre: {
        type: [String, Number],
        required: true,
    },
    mod: {
        type: String,
        default: GLOBALS.DATA_MODE.INS,
        validator(value: string) {
            return Object.values(GLOBALS.DATA_MODE).includes(value)
        },
    },
}

/**
 * Controlla se una data entita' e' presente per una UP (Filiale) oppure no.
 * @param dittaUPTabellaAttivaList entita della UP sul quale bisgona controllare se esiste una data entita (tabella)
 * @param nomeEntita nome dell'entita' da verificare per la figlia (es. DittaUPOpzione)
 * @returns true se esiste altrimenti false
 */
export function isTableUPActive(
    dittaUPTabellaAttivaList?: DittaUPTabellaAttiva[] | null,
    nomeEntita?: NomeTabellaAttivaValue,
) {
    if (!nomeEntita || !dittaUPTabellaAttivaList) return false

    const dati = dittaUPTabellaAttivaList?.find(
        el =>
            el?.nomeTabella?.replaceAll('_', '').toLowerCase() ===
            nomeEntita.replaceAll('_', '').toLowerCase(),
    )

    if (dati) return true
    return false
}

/**
 * @param dittaUPTabellaAttivaList entita della UP sul quale bisgona controllare se esiste una data entita (tabella)
 * @param nomeEntita Nome dell'entita da controlloare per la ditta
 * @param fieldName nome del campo dal quale si vuole prendere il dato
 * @param dataDitta sorgente dei dati della ditta
 * @param dataUP sorgente dei dati dalla UP
 * @param isFiliale indica se considerare l'eventuale dato della filiale (se presente) o meno
 * @returns il dato di interesse o null se non viene trovato
 */
export function getFieldUP(
    dittaUPTabellaAttivaList: DittaUPTabellaAttiva[] | null,
    nomeEntita: string,
    fieldName: string,
    dataDitta: any,
    dataUP: any,
    isFiliale: boolean,
) {
    if (isFiliale) {
        return isTableUPActive(dittaUPTabellaAttivaList, nomeEntita)
            ? get(dataUP, fieldName, null)
            : get(dataDitta, fieldName, null)
    }

    return get(dataDitta, fieldName, null)
}

export async function updateTabellaAttivaList(
    props: DitteProps,
    ditteDettaglioStore: DitteDettaglioStore,
) {
    const params: DittaPayload = {
        requireHasFutureValidity: false,
        orderByKey: true,
        periodoDiRiferimento: { year: Number(props.year), month: Number(props.month) },
        filter: `['id', '=', '${props.id}']`,
        select: JSON.stringify(['id', 'idPadre', 'dittaUPTabellaAttivaList.nomeTabella']),
    }

    try {
        const response = await pagheAPI.anagrafiche.ditte.ditta.get(params)
        if (!response || !response?.data?.responseStatus.isSuccessful || !response.data?.data?.[0])
            return

        ditteDettaglioStore.state.data!.dittaUPTabellaAttivaList =
            response.data.data?.[0]?.dittaUPTabellaAttivaList
    } catch (err) {
        console.error('Errore durante la chiamata: ', err)
    }
}

export async function updateViewAlterazioniList(
    props: DitteProps,
    ditteDettaglioStore: DitteDettaglioStore,
) {
    const params: DittaPayload = {
        requireHasFutureValidity: false,
        orderByKey: true,
        periodoDiRiferimento: { year: Number(props.year), month: Number(props.month) },
        filter: `['id', '=', '${props.id}']`,
        select: JSON.stringify([
            'id',
            'idPadre',
            'ViewDittaPresenzaAlterazioniList.alterazione',
            'ViewDittaPresenzaAlterazioniList.gruppo',
        ]),
    }

    try {
        const response = await pagheAPI.anagrafiche.ditte.ditta.get(params)
        if (!response || !response?.data?.responseStatus.isSuccessful || !response.data?.data?.[0])
            return

        ditteDettaglioStore.state.data!.viewDittaPresenzaAlterazioniList =
            response.data.data?.[0]?.viewDittaPresenzaAlterazioniList
    } catch (err) {
        console.error('Errore durante la chiamata: ', err)
    }
}
