import { RouteLocation } from 'vue-router'

import pagheViews from '@paghe/views'
import utyDate from '@/scripts/services/date'

const tabPropsDefault: TabProps = {
    CODPERMESSO: 'MOVIMENTO',
    CODVOCEMENU: '000008',
    GROUP: 'MovimentiMese',
    ICON: 'far fa-calendar-circle-user',
    TITLE: 'MOVIMENTI DIPENDENTI',
}

const SETTINGS = Object.freeze({
    ELENCO_DITTE: {
        NAME: 'MovDiptiElencoDitte',
        PATH: 'movimenti/calendario-dipendenti/elenco-ditte',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Movimenti mese dipendenti',
    },
    ELENCO_DIPENDENTI: {
        NAME: 'MovDiptiElencoDipendenti',
        PATH: 'movimenti/calendario-dipendenti/elenco-dipendenti/:modalita/:periodo/:idDitta/:idUP/',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Movimenti mese dipendenti',
    },
    DIPENDENTE_DETTAGLIO: {
        NAME: 'MovDiptiDettaglio',
        PATH: 'movimenti/calendario-dipendenti/:modalita/:periodo/:idDitta/:idUP/:id/:rowIdx/:tipoElab',
        TITLE: 'Paghe - Movimenti mese dipendenti',
    },
    DIPENDENTE_CALENDARIO: {
        LABEL: 'Calendario dipendente',
        NAME: 'MovDiptiCalendario',
        PATH: 'calendario',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO_DITTE.PATH,
            name: SETTINGS.ELENCO_DITTE.NAME,
            component: pagheViews.movimenti.calendarioDipendenti.ElencoDitte,
            meta: {
                title: SETTINGS.ELENCO_DITTE.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
        },
        {
            path: SETTINGS.ELENCO_DIPENDENTI.PATH,
            name: SETTINGS.ELENCO_DIPENDENTI.NAME,
            component: pagheViews.movimenti.calendarioDipendenti.ElencoDipendenti,
            meta: {
                title: SETTINGS.ELENCO_DIPENDENTI.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: (route: RouteLocation) => ({
                idDitta: Number(route.params.idDitta),
                idUP: Number(route.params.idUP),
                modalita: route.params.modalita,
                periodo: utyDate.toObject(route.params.periodo + '01'),
            }),
        },
        {
            path: SETTINGS.DIPENDENTE_DETTAGLIO.PATH,
            name: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
            props: true,
            component: pagheViews.movimenti.calendarioDipendenti.Dettaglio,
            meta: {
                title: SETTINGS.DIPENDENTE_DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    component: pagheViews.movimenti.calendarioDipendenti.childs.Calendario,
                    label: SETTINGS.DIPENDENTE_CALENDARIO.LABEL,
                    name: SETTINGS.DIPENDENTE_CALENDARIO.NAME,
                    path: SETTINGS.DIPENDENTE_CALENDARIO.PATH,
                    tab: SETTINGS.DIPENDENTE_CALENDARIO.TAB,
                    meta: {
                        group: SETTINGS.DIPENDENTE_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                    },
                    orderWizard: 0,
                    props: (route: RouteLocation) => ({
                        id: Number(route.params.id),
                        idDitta: Number(route.params.idDitta),
                        idUP: Number(route.params.idUP),
                        modalita: route.params.modalita,
                        periodo: utyDate.toObject(route.params.periodo + '01'),
                        tipoElab: route.params.tipoElab,
                    }),
                },
            ],
        },
    ],
}
