import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/DipteCampoPersonalizzato'

class API {
    @call({ url: `${BASE_URL}/GetDipteCampiPersonalizzati` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<DipteCampiPersonalizzati>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveDipteCampoPersonalizzato` })
    static async save(
        payload: SaveDipteCampoPersonalizzatoPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveDipteCampoPersonalizzatoResponse>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
