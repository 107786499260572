const CollaboratoreAnagrafica = () => import('./CollaboratoreAnagrafica.vue')
const CollaboratoreAreaProduttiva = () => import('./CollaboratoreAreaProduttiva.vue')
const CollaboratoreRapporto = () => import('./CollaboratoreRapporto.vue')
const CollaboratoreDetrazioni = () => import('./CollaboratoreDetrazioni.vue')
const CollaboratoreStorico = () => import('./CollaboratoreStorico.vue')
const CollaboratoreOpzioni = () => import('./CollaboratoreOpzioni.vue')
const CollaboratoreRedditiErogati = () => import('./CollaboratoreRedditiErogati.vue')
const Collaboratore730 = () => import('./Collaboratore730.vue')
const CollaboratoreScadenziario = () => import('./CollaboratoreScadenziario.vue')
const CollaboratoreDiario = () => import('./CollaboratoreDiario.vue')
const CollaboratoreEredi = () => import('./CollaboratoreEredi.vue')
const CollaboratoreCampiPersonalizzati = () => import('./CollaboratoreCampiPersonalizzati.vue')
const CollaboratorePrecRapporto = () => import('./CollaboratorePrecRapporto.vue')
const CollaboratoreAlterazioniContributive = () =>
    import('./CollaboratoreAlterazioniContributive.vue')
const CollaboratoreImpostazioniCedolino1 = () => import('./CollaboratoreImpostazioniCedolino1.vue')
const CollaboratoreImpostazioniCedolino2 = () => import('./CollaboratoreImpostazioniCedolino2.vue')
export {
    Collaboratore730,
    CollaboratoreAnagrafica,
    CollaboratoreAreaProduttiva,
    CollaboratoreDetrazioni,
    CollaboratoreDiario,
    CollaboratoreEredi,
    CollaboratoreOpzioni,
    CollaboratoreRapporto,
    CollaboratoreRedditiErogati,
    CollaboratoreScadenziario,
    CollaboratoreStorico,
    CollaboratoreCampiPersonalizzati,
    CollaboratorePrecRapporto,
    CollaboratoreAlterazioniContributive,
    CollaboratoreImpostazioniCedolino1,
    CollaboratoreImpostazioniCedolino2,
}
