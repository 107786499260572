import { AxiosResponse } from 'axios'

import { call } from '@/scripts/core/axios'

const BASE_URL = '/movimenti'

const defaultPayload: BasePayload = {
    isNuovaValidita: false,
    inserimentoIsAlwaysNuovaValidita: false,
}
class API {
    @call({ url: `${BASE_URL}/SaveCompensiCollaboratori`, defaultPayload })
    static async save(
        payload: SaveCompensiCollaboratori,
    ): Promise<AxiosResponse<BasicResponsePayload<{ hasLogMessages: boolean }>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveInputCompensiCollaboratori`, defaultPayload })
    static async saveInput(
        payload: SaveInputCompensiCollaboratori,
    ): Promise<AxiosResponse<BasicResponsePayload<{ hasLogMessages: boolean }>> | null> {
        return null
    }
}

export default {
    save: API.save,
    saveInput: API.saveInput,
}
