import type { Ref } from 'vue'
import { ref } from 'vue'
import { defineStore } from 'pinia'

import { cloneDeep } from 'lodash'
import { pagheAPI } from '@/api'
import { useGlobalStore } from '@/stores'

export default defineStore('beMessage', () => {
    // state
    const errorMessages: Ref<{ codice: number; descrizione: string }[]> = ref([])

    // actions
    function getDescriptionByCode(codice: number) {
        return errorMessages.value.find(err => err.codice === codice)
    }

    function getMessageByCode(codice: number, ...args: string[]) {
        const message = cloneDeep(getDescriptionByCode(codice))
        if (!message) return ''
        //replace variabili $n
        args.forEach((item, idx) => {
            message.descrizione = message.descrizione.replace(`$${idx + 1}`, item)
        })
        return `${message.descrizione} (cod.${message.codice})`
    }

    async function setErrorMessages() {
        const response = await pagheAPI.tabelle.vMessaggi.get({
            periodoDiRiferimento: useGlobalStore().state.periodoElab,
            filter: "['isFrontEnd','=','true']",
        })
        const { responseStatus, data } = response!.data
        if (responseStatus.isSuccessful && data) {
            errorMessages.value = data.map(err => ({
                codice: err.codice!,
                descrizione: err.descrizione!,
            }))
        }
    }

    return {
        errorMessages,
        getDescriptionByCode,
        getMessageByCode,
        setErrorMessages,
    }
})
