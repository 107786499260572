import { call } from '@/scripts/core/axios'
import type { AxiosResponse } from 'axios'

const BASE_URL = '/VGruppoValore'

class API {
    @call({ url: `${BASE_URL}/GetVGruppoValore` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<VGruppoValore>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVGruppoValore` })
    static async save<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveVGruppoValore>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
