import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VQualificaDipte'

class API {
    @call({ url: `${BASE_URL}/GetVQualificaDipte` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<VQualificaDipte>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVQualificaDipte` })
    static async save(
        payload: SaveVQualificaDipte,
    ): Promise<AxiosResponse<BasicResponsePayload<VQualificaDipte>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
