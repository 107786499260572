import { call } from '@/scripts/core/axios'
import type { AxiosResponse } from 'axios'

const BASE_URL = '/Scadenza'

class API {
    @call({ url: `${BASE_URL}/GetScadenza` })
    static async get(payload: Payload): Promise<AxiosResponse<BasicResponseData<Scadenza>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveScadenza` })
    static async save(
        payload: SaveScadenza,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveScadenza>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveScadenzaPresaVisione` })
    static async saveScadenzaPresaVisione(
        payload: SaveScadenzaPresaVisione,
    ): Promise<AxiosResponse<BasicResponsePayload<{ scadenzaPromemoriaList: Scadenza[] }>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
    saveScadenzaPresaVisione: API.saveScadenzaPresaVisione,
}
