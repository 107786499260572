import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VCodiceUniemens'

class API {
    @call({ url: `${BASE_URL}/GetVCodiceUniemens` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<VCodiceUniemens>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVCodiceUniemens` })
    static async save<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveVCodiceUniemens>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
