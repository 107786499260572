<template>
    <div class="base-input-text">
        <v-file-input
            v-if="inputFile"
            v-bind="attrs"
            v-model="files"
            active
            :class="{ 'v-input--warning': isWarning }"
            append-inner-icon="far fa-paperclip"
            clear-icon="fas fa-xmark"
            :clearable="isClearable"
            density="compact"
            :error-messages="errorMessages"
            :messages="messages"
            :prepend-icon="null"
            :prefix="prefix"
            variant="outlined"
            @update:model-value="updateValue"
        />
        <v-text-field
            v-else
            v-bind="attrs"
            ref="baseInputText"
            :append-inner-icon="appendInnerIcon"
            :class="{
                'v-input--protected': protected && !locked,
                'v-input--protected-locked': protected && locked,
                'v-input--warning': isWarning,
            }"
            clear-icon="fas fa-xmark"
            :clearable="isClearable"
            density="compact"
            :error-messages="errorMessages"
            :messages="messages"
            :model-value="modelValue"
            persistent-placeholder
            :prefix="prefix"
            :tabindex="attrs.readonly ? '-1' : null"
            variant="outlined"
            @blur="(event: FocusEvent) => $emit('blur', event)"
            @click:append-inner="iconClick"
            @mousedown="mouseClick"
            @update:model-value="updateValue"
        >
            <slot />
            <template #append-inner="slotProps">
                <slot
                    name="append-inner"
                    v-bind="slotProps"
                />
            </template>
        </v-text-field>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, useAttrs, watch } from 'vue'
import { castArray, get } from 'lodash'
import Inputmask from 'inputmask'

import { APILogAttivita } from '@/api'
import { useGlobalStore } from '@/stores'
import dateUty from '@/scripts/services/date'

export type MaskGeneric =
    | 'code'
    | 'code0'
    | 'date'
    | 'dateMonth'
    | 'dateTime'
    | 'dateYear'
    | 'decimal0'
    | 'decimal0S'
    | 'decimal2'
    | 'decimal2S'
    | 'decimal3'
    | 'decimal3S'
    | 'decimal5'
    | 'decimal5S'
    | 'decimal6'
    | 'decimal6S'
    | 'decimal7'
    | 'decimal7S'
    | 'lower'
    | 'numeric'
    | 'numericS'
    | 'time'
    | 'upper'

export type MaskSpecific = 'codeEnte'

export interface IBaseInputTextProps {
    blankZero?: boolean
    inputFile?: boolean
    mask?: MaskGeneric | MaskSpecific
    prefix?: string
    protected?: {
        id?: string //ex identificativo
        fieldName: string
        locked?: boolean // ex initLockedValue
        pageName: string
    }
    textAlign?: 'center' | 'left' | 'right'
    warningMessages?: string | string[]
}

const props = withDefaults(defineProps<IBaseInputTextProps>(), {
    blankZero: true,
    inputFile: false,
    mask: undefined,
    prefix: undefined,
    protected: undefined,
    textAlign: undefined,
    warningMessages: () => [],
})

const attrs: any = useAttrs()
const globalStore = useGlobalStore()

const baseInputText = ref()
const files = ref()
const locked = ref(typeof props.protected?.locked === 'boolean' ? props.protected?.locked : true)

const emits = defineEmits(['blur', 'change', 'lockChange'])

const appendInnerIcon = computed(() =>
    props.protected ? `fas fa-lock${locked.value ? '' : '-open'}` : attrs?.appendInnerIcon || null,
)

const errorMessages = computed(() => {
    if (attrs.disabled) return []
    const errors = get(attrs, 'error-messages', [])
    return errors.length ? errors : get(attrs, 'errorMessages', [])
})

const isClearable = computed(() => {
    if (props.protected && locked.value) return false
    return typeof attrs.clearable === 'boolean' ? attrs.clearable : !attrs?.readonly
})

const isDate = computed(() => props.mask?.startsWith('date'))

const isWarning = computed(() => !attrs.errorMessages?.length && !!props.warningMessages.length)

const messages = computed(() => {
    let messages: string[] = castArray(props.warningMessages).slice(0, 1)
    if (!messages.length && attrs.messages) {
        messages = castArray(attrs.messages).slice(0, 1) as typeof messages
    }
    return messages
})

const modelValue = computed(() => {
    let value: any = 'modelValue' in attrs ? attrs.modelValue : attrs?.['model-value']
    if (props.mask?.startsWith('decimal') && typeof value !== 'string') {
        if (!value) return props.blankZero ? null : 0
        let decimal = parseInt(props.mask.split('decimal')[1])
        value = value.toLocaleString('it-IT', { minimumFractionDigits: decimal })
        const [int, dec] = value.split(',')
        value = `${int},${(dec || '').padEnd(decimal, '0')}`
    }
    if (isDate.value) {
        switch (props.mask) {
            case 'date': // DD/MM/YYYY
                value = dateUty.format(value) || null
                break
            case 'dateMonth': // MM/YYYY
                value = dateUty.format(value, 'monthYear') || null
                break
            case 'dateTime': // DD/MM/YYYY - hh:mm
                value = dateUty.format(value, 'dateTime') || null
                break
        }
    }
    return value
})

async function iconClick() {
    if (props.protected && locked.value) {
        globalStore.loadingSet()
        const result = await APILogAttivita.create({
            identificativo: props.protected?.id || 'ATTIVA_INPUT_TEXT',
            testo: `${props.protected?.pageName}_${props.protected?.fieldName}`,
        })
        globalStore.loadingUnset()
        if (result.data.responseStatus.isSuccessful) {
            baseInputText.value.$el.classList.remove('v-input--protected-locked')
            baseInputText.value.$el.classList.add('v-input--protected')
            const element = baseInputText.value.$el.querySelector('.v-field')
            if (element) {
                element.classList.add('v-field--focused')
                baseInputText.value.$el
                element.querySelector('.v-field__input input').removeAttribute('readonly')
                baseInputText.value.focus()
            }
            locked.value = false
            emits('lockChange', locked.value)
        }
    }
}

function maskInput() {
    if (!props.mask) return
    const input = baseInputText.value?.$el.querySelector('.v-field__input input')
    if (input) {
        const settings: any = {
            nullable: true,
            placeholder: '',
            prefillYear: false,
        }
        let mask = false
        switch (props.mask) {
            // GENERICS
            case 'code': // campo codice
                input.style.textAlign = 'center'
                input.style.textTransform = 'uppercase'
                break
            case 'code0': // campo codice numerico (leading zeroes)
                mask = true
                input.style.textAlign = 'center'
                settings.regex = `\\d${attrs.maxlength ? `{${attrs.maxlength}}` : '*'}`
                break
            case 'lower':
            case 'upper':
                input.style.textTransform = `${props.mask}case`
                break
            // DATE/TIME
            case 'date':
                mask = true
                settings.alias = 'datetime'
                settings.inputFormat = 'dd/mm/yyyy'
                settings.max = attrs.max || '31/12/9999'
                settings.min = attrs.min || '01/01/1900'
                settings.placeholder = 'GG/MM/AAAA'
                break
            case 'dateMonth':
                mask = true
                settings.alias = 'datetime'
                settings.inputFormat = 'mm/yyyy'
                settings.max = attrs.max || '12/9999'
                settings.min = attrs.min || '01/1900'
                settings.placeholder = 'MM/AAAA'
                break
            case 'dateTime':
                mask = true
                settings.alias = 'datetime'
                settings.inputFormat = 'dd/mm/yyyy - HH:MM'
                settings.max = attrs.max || '31/12/9999 - 23:59'
                settings.min = attrs.min || '01/01/1900 - 00:00'
                settings.placeholder = 'GG/MM/AAAA - OO:MM'
                break
            case 'dateYear':
                mask = true
                settings.alias = 'datetime'
                settings.inputFormat = 'yyyy'
                settings.max = attrs.max || '9999'
                settings.min = attrs.min || '1900'
                settings.placeholder = 'AAAA'
                break
            case 'time':
                mask = true
                settings.regex = '^([01]\\d{1}|[2][0-3]):([0-5]\\d{1})$'
                settings.placeholder = 'OO:MM'
                break
            // NUMBERS
            case 'decimal0':
            case 'decimal0S':
            case 'decimal2':
            case 'decimal2S':
            case 'decimal3':
            case 'decimal3S':
            case 'decimal5':
            case 'decimal5S':
            case 'decimal6':
            case 'decimal6S':
            case 'decimal7':
            case 'decimal7S':
                mask = true
                settings.alias = 'decimal'
                settings.allowMinus = props.mask.endsWith('S')
                settings.digits = parseInt(props.mask.split('decimal')[1])
                settings.groupSeparator = '.'
                settings.radixPoint = ','
                settings.skipOptionalPartCharacter = ','
                let maxLength = (attrs.maxlength as number) || 10
                settings.max = 10 ** maxLength
                input.removeAttribute('maxlength')
                break
            case 'numeric':
            case 'numericS':
                mask = true
                settings.alias = 'numeric'
                settings.allowMinus = props.mask === 'numericS'
                break
            // CUSTOM
            case 'codeEnte':
                mask = true
                settings.autoUnmask = true
                settings.mask = '9{4}[.9{4}]'
                break
        }
        Inputmask.remove(input)
        if (mask) Inputmask(settings).mask(input)
        if (props.textAlign) input.style.textAlign = props.textAlign
    }
}

function mouseClick() {
    if (props.protected && locked.value) {
        const element = baseInputText.value.$el.querySelector('.v-field--focused')
        if (element) element.classList.remove('v-field--focused')
    }
}

function updateValue(value: any) {
    if (isDate.value) {
        let byFormat = undefined
        switch (props.mask) {
            case 'date':
                byFormat = dateUty.FORMATS.DATE
                break
            case 'dateMonth':
                byFormat = dateUty.FORMATS.MONTH_YEAR
                break
            case 'dateTime':
                byFormat = dateUty.FORMATS.DATE_TIME
                break
            case 'dateYear':
                byFormat = dateUty.FORMATS.YEAR
                break
        }
        value = dateUty.toISO(value, false, byFormat) || value
    }
    emits('change', { currentValue: value, oldValue: modelValue.value })
}

watch(
    () => [attrs.length, attrs.max, attrs.min],
    async () => {
        if (attrs.length || attrs.max || attrs.min) {
            maskInput()
            if (modelValue.value) {
                const input = baseInputText.value?.$el.querySelector('.v-field__input input')
                if (input) {
                    input.setSelectionRange(modelValue.value.length, modelValue.value.length)
                }
            }
        }
    },
)

onMounted(() => {
    maskInput()
    nextTick(() => {
        // Campo protetto:
        // per disabilitare soltanto l'input e non anche l'icona. Se si usa l'attributo readonly
        // direttamente su v-input-text disabilita tutto
        if (props.protected && locked.value) {
            baseInputText.value.$el.classList.remove('v-input--readonly')
            baseInputText.value.$el
                .querySelector('.v-field__input input')
                ?.setAttribute('readonly', 'true')
        }
    })
})
</script>

<style lang="scss">
@use '@/styles/global/mixins';
@use '@/styles/global/vars';

.base-input-text {
    @include mixins.input_field;
    .v-input {
        &.v-input--protected {
            &-locked {
                .v-field {
                    background-color: var(--bg-input-readonly) !important;
                }
                .v-field__append-inner .v-icon:not(.fa-lock) {
                    pointer-events: none;
                }
            }
            &:not(-locked) {
                .v-field__append-inner .v-icon.fa-lock-open {
                    pointer-events: none;
                }
            }
        }
        .v-field__append-inner .v-icon {
            cursor: pointer;
            font-size: large;
            font-weight: bold;
        }
        .v-field__clearable .v-icon {
            font-size: larger;
        }
    }
}
</style>
